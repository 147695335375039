import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { GemcLossRunEmail } from '../../ApiTypes/GemcLossRunEmail';
import { FaEdit, FaShare } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

export default function GemcLossRunEmailTable({
  emails,
  handleEmailEdit,
  handleSendTest,
}: {
  emails: GemcLossRunEmail[];
  handleEmailEdit: (email: GemcLossRunEmail | null) => void;
  handleSendTest: (email: GemcLossRunEmail) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => emails, [emails]);

  const columnData: ColumnDef<GemcLossRunEmail>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Edit'
              onClick={() => {
                handleEmailEdit(d.row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Send Test'
              onClick={() => {
                handleSendTest(d.row.original);
              }}
            >
              <FaShare />
            </Button>
          </div>
        );
      },
    },

    {
      header: 'Email Address',
      accessorKey: 'emailaddress',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Employer Name',
      accessorKey: 'employerName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'EmployerId',
      accessorFn: (d) => `${d.employerid}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Send To',
      accessorFn: (d) => (d.active ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 75,
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
