import React, { useState, useEffect } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { Button, Form, FormGroup, Spinner } from 'react-bootstrap';
import FieldBSRenderTextHorizontal from '../../Common/FieldBSRenderTextHorizontal';
import FieldBSRenderDateHorizontal from '../../Common/FieldBSRenderDateHorizontal';
import FieldBSRenderSelectHorizontal from '../../Common/FieldBSRenderSelectHorizontal';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';

import styles from './index.module.css';
import FieldBSRenderTextAreaHorizontal from '../../Common/FieldBSRenderTextAreaHorizontal';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import { State } from '../../../ApiTypes/State';
import { Adjuster } from '../../../ApiTypes/Adjuster';
import { County } from '../../../ApiTypes/County';
import { InjuryCode } from '../../../ApiTypes/InjuryCode';
import { BodyPartItem } from '../../../ApiTypes/BodyPart';
import {
  displayDateOnly,
  parseDateTimesForServer,
  parseDatesForServer,
} from '../../../Utils';
import {
  composeValidators,
  requiredField,
  zipLength,
} from '../../../Utils/FieldValidation';
import { Claim } from '../../../ApiTypes/Claim';
import { clearSelectedClaim } from '../../../Actions/index';
import { ClaimStatus } from '../../../ApiTypes/ClaimStatus';
import { EntityUser } from '../../../ApiTypes/EntityUser';
import { MedicareItem } from '../../../ApiTypes/MedicareItem';
import { Shift } from '../../../ApiTypes/Shift';
import { EmployerPolicy } from '../../../ApiTypes/EmployerPolicy';
import { Department } from '../../../ApiTypes/Department';
import { Location } from '../../../ApiTypes/Location';
import { useNavigate, useParams } from 'react-router-dom';
import accounting from 'accounting';
import ErrorField from '../../Common/ErrorField';
import ClaimApi from '../../../Api/ClaimApi';
import { ToastContentProps, toast } from 'react-toastify';
import { fetchEmployerLocationsByPolicyId } from '../../../Actions/EmployerActions';
import { CreateClaimRequest } from '../../../ApiTypes/CreateClaimRequestResponse';
import { fetchFullClaimByClaimNumber } from '../../../Actions/ClaimActions';
import { fetchEmployerPolicyClassCodes } from '../../../Actions/ReferenceActions';
import { PolicyClassCode } from '../../../ApiTypes/PolicyClassCode';
import { FieldValidator, FormApi } from 'final-form';
import FieldBSRenderTimeHorizontal from '../../Common/FieldBSRenderTimeHorizontal';
import {
  ClaimTypes,
  ClaimTypesURL,
} from '../../../ApiTypes/ClaimTypeConfiguration';
import { ClaimTabsEnum } from '../../../ApiTypes/ClaimTypeTab';
import {
  ClaimCustomOption,
  OptionTypeEnum,
} from '../../../ApiTypes/ClaimCustomOption';
import { ClaimSubType } from '../../../ApiTypes/ClaimSubType';
import { updateCurrentEmployerPolicyInReducer } from '../../../Actions/CurrentClaimActions';
import FieldBSRenderSelectHorizontalSearchable from '../../Common/FieldBSRenderSelectHorizontalSearchable';
import { useBlankModal } from '../../Common/useBlankModal';
import BlankModal from '../../Common/BlankModal';

export default function InformationTab({
  showClaimantForm,
  setShowClaimantForm,
  showEmployerSelect,
  setShowEmployerSelect,
  claimType,
}: {
  showClaimantForm: boolean;
  setShowClaimantForm: (show: boolean) => void;
  showEmployerSelect: boolean;
  setShowEmployerSelect: (show: boolean) => void;
  claimType: number;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { claimNumber } = useParams();
  const [premium, setPremium] = useState<string>('');
  const [defaultSir, setDefaultSir] = useState<string>('');
  const [corridorDeductible, setCorridorDeductible] = useState<string>('');
  const [audited, setAudited] = useState<boolean>(false);
  const [countiesForState, setCountiesForState] = useState<County[]>([]);
  const {
    showBlankModal,
    blankModalMessage,
    setShowBlankModal,
    setBlankModalMessage,
  } = useBlankModal();
  const { claimTypeConfiguration } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const {
    adjusters,
    bodyParts,
    counties,
    causes,
    results,
    policyClassCodes,
    states,
    claimStatuses,
    shifts,
    medicares,
    supervisors,
    claimCustomOptions,
    insuranceCompanies,
  } = useAppSelector((state) => state.reference);

  const { departments, policies, locations } = useAppSelector(
    (state) => state.employer
  );

  const { claim, claimExcessPolicy, claimSubTypes, employer, claimant } =
    useAppSelector((state) => state.currentClaimReducer);

  let formInstance: FormApi<Claim, Partial<Claim>>;
  let formValues: Claim;

  useEffect(() => {
    getExcessCarrierValues();

    return () => {
      getExcessCarrierValues();
    };
  }, [claimExcessPolicy]);

  useEffect(() => {
    if (claim && claim.state) {
      getCounties(claim.state);
    } else {
      getCounties('GA');
    }
    if (claim && claim.policyId) {
      dispatch(fetchEmployerPolicyClassCodes(claim.policyId));
    }
  }, [claim]);

  useEffect(() => {
    if (
      claim &&
      !claim.policyId &&
      !formValues?.policyId &&
      policies.length > 0
    ) {
      findCurrentPolicy(policies);
    }
  }, [policies, formValues!, claim]);

  const findCurrentPolicy = (policies: EmployerPolicy[]) => {
    const today = new Date();
    const current = policies.find((x) => {
      const start = new Date(x.startDate ?? '');
      const end = new Date(x.endDate ?? '');

      if (today <= end && today >= start) {
        return x;
      }
    });
    if (current) {
      formInstance.change('policyId', current.policyId);
      handlePolicyChange(formValues?.employerId ?? 0, current.policyId);
    }
  };

  const getCounties = (state: string) => {
    if (state) {
      const countiesNeeded = counties.filter(
        (c) =>
          c.stateName === states.find((s) => s.stateAbbr === state)?.stateName
      );
      setCountiesForState(countiesNeeded);
    } else {
      setCountiesForState([]);
    }
  };

  const handlePolicyChange = (employerId: number, policyId: number) => {
    if (employerId && policyId) {
      dispatch(fetchEmployerLocationsByPolicyId({ employerId, policyId }));
    }
    if (policyId) {
      dispatch(fetchEmployerPolicyClassCodes(policyId));
      dispatch(updateCurrentEmployerPolicyInReducer(policyId));
    }
    handleUWNotes(employerId ?? 0, policyId ?? 0);
  };

  const handleUWNotes = async (employerId: number, policyId: number) => {
    await ClaimApi.getEmployerPolicyUWNotes(employerId, policyId)
      .then((res) => {
        if (res.data && res.data[0] && res.data[0].length > 0) {
          setBlankModalMessage(
            <div>
              {res.data != null && res.data.length > 0 && (
                <div>
                  <b>UW Notes:</b>
                  <br />
                  <ul>
                    {res.data.map((note) => (
                      <li>{note}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          );
          setShowBlankModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getExcessCarrierValues = () => {
    if (claimExcessPolicy) {
      const p =
        claimExcessPolicy.premium != null
          ? accounting.formatMoney(claimExcessPolicy?.premium)
          : '';
      const d =
        claimExcessPolicy.defaultSir != null
          ? accounting.formatMoney(claimExcessPolicy?.defaultSir)
          : '';
      const c =
        claimExcessPolicy.corridorDeductible != null
          ? accounting.formatMoney(claimExcessPolicy?.corridorDeductible)
          : '';
      const a = claimExcessPolicy.audited;

      setPremium(p);
      setDefaultSir(d);
      setCorridorDeductible(c);
      setAudited(a);
    } else {
      setPremium('');
      setDefaultSir('');
      setCorridorDeductible('');
      setAudited(false);
    }
  };

  const Msg = ({ closeToast, toastProps }: Partial<ToastContentProps>) => (
    <div>
      {(toastProps?.data as { text: string }).text}
      <div className='d-flex justify-content-around align-items-center my-3'>
        <Button
          type='button'
          variant='outline-light'
          size='sm'
          onClick={() => {
            closeToast && closeToast();
            navigate(
              `/${ClaimTypesURL[claimType]}/${claimNumber}/sbwc-reporting?final=true`
            );
          }}
        >
          Yes
        </Button>
        <Button
          variant='outline-light'
          size='sm'
          type='button'
          onClick={() => {
            closeToast && closeToast();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  const displayToast = () => {
    toast(<Msg />, {
      autoClose: false,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      data: {
        text: `Would you like to file a Final Report with SBWC?`,
      },
    });
  };

  const handleCompleteDiaries = () => {
    console.log('complete diaries');
    return ClaimApi.completeDiaries(claimNumber!)
      .then((res) => {
        if (res.data.success) {
          toast.success('Diaries Completed!', { autoClose: 1000 });
        } else {
          toast.error(res.data.message);
        }
        displayToast();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const CompleteDiaries = ({
    closeToast,
    toastProps,
  }: Partial<ToastContentProps>) => (
    <div>
      {(toastProps?.data as { text: string }).text}
      <div className='d-flex justify-content-around align-items-center my-3'>
        <Button
          type='button'
          variant='outline-light'
          size='sm'
          onClick={() => {
            closeToast && closeToast();
            handleCompleteDiaries();
          }}
        >
          Yes
        </Button>
        <Button
          variant='outline-light'
          size='sm'
          type='button'
          onClick={() => {
            closeToast && closeToast();
            displayToast();
          }}
        >
          No
        </Button>
      </div>
    </div>
  );

  const displayClosedClaimToast = () => {
    toast(<CompleteDiaries />, {
      autoClose: false,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      data: {
        text: `Would you like to complete all diaries?`,
      },
    });
  };

  const handleStatusChange = (status: string | null) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var todayString = yyyy + '-' + mm + '-' + dd + 'T00:00:00';
    if (status === claim?.status) {
      formInstance.change('statusEffectiveDate', claim.statusEffectiveDate);
    } else {
      formInstance.change('statusEffectiveDate', todayString);
    }
  };

  const onSubmit = (values: Claim) => {
    if (!values.claimantId && claimant) {
      values.claimantId = claimant.claimantId;
    }
    if (!values.employerId && employer) {
      values.employerId = employer.employerId;
    }
    if (!values.insurcoid && employer && insuranceCompanies) {
      const insurCo = insuranceCompanies.find(
        (f) => f.accountnumber === employer.accountNumber
      );
      values.insurcoid = insurCo?.insurcoid ?? null;
    }
    if (values.claimNo) {
      const shouldAsk =
        claim?.status?.toUpperCase() !== 'C' && values.status === 'C';
      return ClaimApi.updateClaim(values)
        .then((res) => {
          if (res.data.success) {
            toast.success('Claim Saved!', { autoClose: 1000 });
            if (claimNumber) {
              dispatch(fetchFullClaimByClaimNumber(claimNumber!));
              if (shouldAsk) {
                displayClosedClaimToast();
              }
            } else {
              navigate(`/${ClaimTypesURL[claimType]}/${values.claimNo}`);
            }
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const request: CreateClaimRequest = {
        policyId: values.policyId ?? 0,
        claimTypeId: claimType,
      };

      return ClaimApi.createSkeletonClaim(request)
        .then((res) => {
          if (res.data.success) {
            values.claimNo = res.data.claimNumber;
            values.claimTypeId = claimType;
            return ClaimApi.updateClaim(values)
              .then((resp) => {
                if (resp.data.success) {
                  navigate(
                    `/${ClaimTypesURL[claimType]}/${res.data.claimNumber}`
                  );
                } else {
                  toast.error(resp.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
                toast.error('Failed to create claim');
              });
          } else {
            toast.error(res.data.errorMessage);
          }
        })
        .catch((err) => {
          toast.error('Failed to create claim number');
        });
    }
  };

  return (
    <div className='w-100'>
      <RFForm
        onSubmit={onSubmit}
        validate={(values: Claim) => {
          const errors: {
            [Property in keyof Claim]?: string;
          } = {};

          if (
            !values?.severity &&
            claimTypeConfiguration?.find(
              (f) =>
                f.claimTabId === ClaimTabsEnum.Info &&
                f.fieldName === 'severity'
            )?.required
          ) {
            errors.severity = 'Required';
          }
          if (!values?.claimantId && !claimant?.claimantId) {
            errors.claimantId = 'Claimant Required';
          }

          if (!values?.employerId && !employer?.employerId) {
            errors.employerId = 'Employer Required';
          }
          if (
            !values?.initialTreatmentGiven &&
            claimTypeConfiguration?.find(
              (f) =>
                f.claimTabId === ClaimTabsEnum.Info &&
                f.fieldName === 'initialTreatmentGiven'
            )?.required
          ) {
            errors.initialTreatmentGiven = 'Required';
          }
          if (
            !values?.accidentZip &&
            claimTypeConfiguration?.find(
              (f) =>
                f.claimTabId === ClaimTabsEnum.Info &&
                f.fieldName === 'accidentZip'
            )?.required
          ) {
            errors.accidentZip = 'Required';
          }
          if (values.policyId && (values.incidentDate || values.injuryDate)) {
            var policy = policies.find((x) => x.policyId === values.policyId);
            if (
              values.incidentDate &&
              policy !== null &&
              claimTypeConfiguration?.find(
                (f) =>
                  f.claimTabId === ClaimTabsEnum.Info &&
                  f.fieldName === 'incidentDate'
              )?.visible
            ) {
              if (policy?.startDate && policy?.endDate) {
                var incidentDate = Date.parse(values.incidentDate);
                var startDate = Date.parse(policy?.startDate.slice(0, 10));
                var endDate = Date.parse(policy?.endDate.slice(0, 10));
                if (incidentDate < startDate || incidentDate > endDate) {
                  errors.policyId =
                    'Incident Date must fall in between Policy coverage range.';
                }
              }
            }
            if (
              values.injuryDate &&
              policy !== null &&
              claimTypeConfiguration?.find(
                (f) =>
                  f.claimTabId === ClaimTabsEnum.Info &&
                  f.fieldName === 'injuryDate'
              )?.visible
            ) {
              if (policy?.startDate && policy?.endDate) {
                var injuryDate = Date.parse(values.injuryDate);
                var startDate = Date.parse(policy?.startDate.slice(0, 10));
                var endDate = Date.parse(policy?.endDate.slice(0, 10));
                if (injuryDate < startDate || injuryDate > endDate) {
                  errors.policyId =
                    'Injury Date must fall in between Policy coverage range.';
                }
              }
            }
          }
          return errors;
        }}
        initialValues={
          claim && claim.claimNo
            ? {
                ...claim,
                controverted: claim?.controverted ?? false,
                severity: claim?.severity ?? '',
                accidentState: claim?.accidentState ?? 'GA',
              }
            : {
                controverted: false,
                severity: '',
                accidentState: 'GA',
              }
        }
        render={({ handleSubmit, form, values, pristine, submitting }) => {
          const severityFieldState = form.getFieldState(
            'severity' as keyof Claim
          )!;
          formInstance = form;
          formValues = values;
          return (
            <Form onSubmit={handleSubmit}>
              <div className={`${styles.form}`}>
                <div className={`${styles.column}`}>
                  <div className='w-100 d-flex'>
                    <div className='w-50 pe-2'>
                      <Field
                        name='claimNo'
                        type='text'
                        label='Claim Number'
                        placeholder='Click save to generate Claim No'
                        component={FieldBSRenderTextHorizontal}
                        disabled
                      />
                    </div>
                    <div className='w-50 pe-2'>
                      <div className='d-flex justify-content-around'>
                        <ErrorField name='claimantId' />
                        <ErrorField name='employerId' />
                      </div>
                    </div>
                  </div>
                  <div className='w-100 d-flex'>
                    <div className='w-50 pe-2'>
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'status'
                      )?.visible && (
                        <Field
                          name='status'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'status'
                            )?.labelName ?? 'Claim Status'
                          }
                          options={claimStatuses.filter(
                            (x) => x.status !== 'I'
                          )}
                          optionMethod={(options: ClaimStatus[]) =>
                            options.map((o) => (
                              <option key={o.status} value={o.status}>
                                {o.statusDesc}
                              </option>
                            ))
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'status'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderSelectHorizontal}
                          onChange={handleStatusChange}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'affiliateClaimNumber'
                      )?.visible && (
                        <Field
                          name='affiliateClaimNumber'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'affiliateClaimNumber'
                            )?.labelName ?? 'Net Claim Number'
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'affiliateClaimNumber'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderTextAreaHorizontal}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'injuryDate'
                      )?.visible && (
                        <Field
                          name='injuryDate'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'injuryDate'
                            )?.labelName ?? 'Injury Date'
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'injuryDate'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          parse={parseDatesForServer}
                          component={FieldBSRenderDateHorizontal}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'incidentDate'
                      )?.visible && (
                        <Field
                          name='incidentDate'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'incidentDate'
                            )?.labelName ?? 'Incident Date'
                          }
                          parse={parseDatesForServer}
                          component={FieldBSRenderDateHorizontal}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'incidentDate'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'reportDate'
                      )?.visible && (
                        <Field
                          name='reportDate'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'reportDate'
                            )?.labelName ?? 'Report Date'
                          }
                          parse={parseDatesForServer}
                          component={FieldBSRenderDateHorizontal}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'reportDate'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'accidentAddress'
                      )?.visible && (
                        <Field
                          name='accidentAddress'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentAddress'
                            )?.labelName ?? 'Address'
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentAddress'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderTextAreaHorizontal}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'accidentCity'
                      )?.visible && (
                        <Field
                          name='accidentCity'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentCity'
                            )?.labelName ?? 'City'
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentCity'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderTextAreaHorizontal}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'accidentState'
                      )?.visible && (
                        <Field
                          name='accidentState'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentState'
                            )?.labelName ?? 'State of Injury'
                          }
                          options={states}
                          optionMethod={(options: State[]) =>
                            options.map((o) => (
                              <option key={o.stateAbbr} value={o.stateAbbr}>
                                {o.stateAbbr}
                              </option>
                            ))
                          }
                          onChange={getCounties}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentState'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderSelectHorizontal}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'accidentZip'
                      )?.visible && (
                        <Field
                          name='accidentZip'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentZip'
                            )?.labelName ?? 'Zip'
                          }
                          validate={zipLength}
                          maxLength={5}
                          component={FieldBSRenderTextAreaHorizontal}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'accidentCounty'
                      )?.visible && (
                        <Field
                          name='accidentCounty'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentCounty'
                            )?.labelName ?? 'County of Injury'
                          }
                          options={countiesForState}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'accidentCounty'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          optionMethod={(options: County[]) =>
                            options.map((o) => ({
                              value: o.countyName,
                              label: o.countyName,
                            }))
                          }
                          component={FieldBSRenderSelectHorizontalSearchable}
                        />
                      )}
                    </div>
                    <div className='w-50 ps-2'>
                      <Field
                        name='statusEffectiveDate'
                        type='text'
                        label='Status Effective'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDateHorizontal}
                        disabled
                      />
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'tpaReceiveDate'
                      )?.visible && (
                        <Field
                          name='tpaReceiveDate'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'tpaReceiveDate'
                            )?.labelName ?? 'TPA Received'
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'tpaReceiveDate'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          parse={parseDatesForServer}
                          component={FieldBSRenderDateHorizontal}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'supervisorUserId'
                      )?.visible && (
                        <Field
                          name='supervisorUserId'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'supervisorUserId'
                            )?.labelName ?? 'Supervisor'
                          }
                          options={supervisors}
                          optionMethod={(options: EntityUser[]) =>
                            options.map((o) => (
                              <option key={o.userId} value={o.userId}>
                                {o.userName}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelectHorizontal}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'supervisorUserId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'adjuster'
                      )?.visible && (
                        <Field
                          name='adjuster'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'adjuster'
                            )?.labelName ?? 'Adjuster'
                          }
                          options={adjusters}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'adjuster'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          optionMethod={(options: Adjuster[]) =>
                            options.map((o) => ({
                              value: o.adjusterId,
                              label: o.firstName + ' ' + o.lastName,
                            }))
                          }
                          component={FieldBSRenderSelectHorizontalSearchable}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-100'>
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'occupation'
                    )?.visible && (
                      <Field
                        name='occupation'
                        type='text'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'occupation'
                          )?.labelName ?? 'Occupation'
                        }
                        component={FieldBSRenderTextHorizontal}
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'occupation'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                      />
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'causeId'
                    )?.visible && (
                      <Field
                        name='causeId'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'causeId'
                          )?.labelName ?? 'Cause'
                        }
                        options={claimCustomOptions
                          .filter(
                            (x) =>
                              x.type === OptionTypeEnum.ClaimCauses &&
                              (x.claimTypeId === null ||
                                x.claimTypeId === claimType)
                          )
                          .sort((a, b) => a.name!.localeCompare(b.name!))}
                        optionMethod={(options: ClaimCustomOption[]) =>
                          options.map((o) => ({ value: o.id, label: o.name }))
                        }
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'causeId'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                        component={FieldBSRenderSelectHorizontalSearchable}
                      />
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'cause'
                    )?.visible && (
                      <Field
                        name='cause'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'cause'
                          )?.labelName ?? 'Cause'
                        }
                        options={causes.filter((x) => x.code !== '0')}
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'cause'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                        optionMethod={(options: InjuryCode[]) =>
                          options.map((o) => ({
                            value: o.code,
                            label:
                              (o.code.length === 1 ? '0' + o.code : o.code) +
                              (' - ' + o.category + ' - ' + o.description),
                          }))
                        }
                        component={FieldBSRenderSelectHorizontalSearchable}
                      />
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'bodyPart'
                    )?.visible && (
                      <Field
                        name='bodyPart'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'bodyPart'
                          )?.labelName ?? 'Body Part'
                        }
                        options={bodyParts.filter(
                          (x) => x.bodyPartCode !== '0'
                        )}
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'bodyPart'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                        optionMethod={(options: BodyPartItem[]) =>
                          options.map((o) => ({
                            value: o.bodyPartCode,
                            label: o.bodyPartDescription,
                          }))
                        }
                        component={FieldBSRenderSelectHorizontalSearchable}
                      />
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.claimTypeId === ClaimTypes.WorkersComp &&
                        f.fieldName === 'result'
                    )?.visible && (
                      <Field
                        name='result'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'result'
                          )?.labelName ?? 'Result'
                        }
                        options={results.filter((x) => x.code !== '0')}
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'result'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                        optionMethod={(options: InjuryCode[]) =>
                          options.map((o) => ({
                            value: o.code,
                            label:
                              (o.code.length === 1 ? '0' + o.code : o.code) +
                              (' - ' + o.category + ' - ' + o.description),
                          }))
                        }
                        component={FieldBSRenderSelectHorizontalSearchable}
                      />
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        (f.claimTypeId === ClaimTypes.AutomobileLiability ||
                          f.claimTypeId === ClaimTypes.GeneralLiability) &&
                        f.fieldName === 'customResultId'
                    )?.visible && (
                      <Field
                        name='customResultId'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              (f.claimTypeId ===
                                ClaimTypes.AutomobileLiability ||
                                f.claimTypeId ===
                                  ClaimTypes.GeneralLiability) &&
                              f.fieldName === 'customResultId'
                          )?.labelName ?? 'Result'
                        }
                        options={claimCustomOptions.filter(
                          (x) =>
                            x.type === OptionTypeEnum.ClaimResultCodes &&
                            x.claimTypeId === claimType
                        )}
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              (f.claimTypeId ===
                                ClaimTypes.AutomobileLiability ||
                                f.claimTypeId ===
                                  ClaimTypes.GeneralLiability) &&
                              f.fieldName === 'customResultId'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                        optionMethod={(options: ClaimCustomOption[]) =>
                          options.map((o) => ({
                            value: o.id,
                            label: o.name,
                          }))
                        }
                        component={FieldBSRenderSelectHorizontalSearchable}
                      />
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'description'
                    )?.visible && (
                      <Field
                        name='description'
                        type='text'
                        rows={3}
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'description'
                          )?.labelName ?? 'Injury Description'
                        }
                        component={FieldBSRenderTextAreaHorizontal}
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'description'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                      />
                    )}
                    <div className='d-flex w-100 d-flex align-items-start'>
                      <div className='w-50 pe-2 mb-2'>
                        {claimTypeConfiguration?.find(
                          (f) =>
                            f.claimTabId === ClaimTabsEnum.Info &&
                            f.fieldName === 'authoritiesContacted'
                        )?.visible && (
                          <Field
                            name='authoritiesContacted'
                            label={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'authoritiesContacted'
                              )?.labelName ?? 'Police authorities contacted'
                            }
                            checked={values?.authoritiesContacted}
                            validate={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'authoritiesContacted'
                              )?.required
                                ? requiredField
                                : undefined
                            }
                            component={FieldBSRenderCheckbox}
                            type='checkbox'
                          />
                        )}
                      </div>
                      <div className='w-50 ps-2'>
                        {claimTypeConfiguration?.find(
                          (f) =>
                            f.claimTabId === ClaimTabsEnum.Info &&
                            f.fieldName === 'accidentReportNumber'
                        )?.visible && (
                          <Field
                            name='accidentReportNumber'
                            label={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'accidentReportNumber'
                              )?.labelName ?? 'If yes, Accident Report Number'
                            }
                            component={FieldBSRenderTextHorizontal}
                            validate={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'accidentReportNumber'
                              )?.required
                                ? requiredField
                                : undefined
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className=' w-100 '>
                      <div className='w-100'>
                        {/* <FormGroup> */}
                        {claimTypeConfiguration?.find(
                          (f) =>
                            f.claimTabId === ClaimTabsEnum.Info &&
                            f.fieldName === 'initialTreatmentGiven'
                        )?.visible && (
                          <div>
                            <p>
                              {claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'initialTreatmentGiven'
                              )?.labelName ??
                                'What initial treatment was given?'}
                            </p>
                            <div className='mb-3'>
                              <div
                                className={`d-flex justify-content-start  ${styles.flexGap1rem}`}
                              >
                                <Field
                                  name='initialTreatmentGiven'
                                  type='radio'
                                  label='No Initial Treatment'
                                  value={0}
                                  checked={
                                    values?.initialTreatmentGiven?.toString() ===
                                    '0'
                                  }
                                  component={FieldBSRenderCheckbox}
                                />
                                <Field
                                  name='initialTreatmentGiven'
                                  type='radio'
                                  label='Minor By Employer'
                                  value={1}
                                  checked={
                                    values?.initialTreatmentGiven?.toString() ===
                                    '1'
                                  }
                                  component={FieldBSRenderCheckbox}
                                />
                                <Field
                                  name='initialTreatmentGiven'
                                  type='radio'
                                  label='Minor: Clinical/Hospital'
                                  value={2}
                                  checked={
                                    values?.initialTreatmentGiven?.toString() ===
                                    '2'
                                  }
                                  component={FieldBSRenderCheckbox}
                                />
                              </div>
                              <div
                                className={`d-flex justify-content-start  ${styles.flexGap1rem}`}
                              >
                                <Field
                                  name='initialTreatmentGiven'
                                  type='radio'
                                  label='Emergency Room'
                                  value={3}
                                  checked={
                                    values?.initialTreatmentGiven?.toString() ===
                                    '3'
                                  }
                                  component={FieldBSRenderCheckbox}
                                />
                                <Field
                                  name='initialTreatmentGiven'
                                  type='radio'
                                  label='Hospitalized > 24 hrs'
                                  value={4}
                                  checked={
                                    values?.initialTreatmentGiven?.toString() ===
                                    '4'
                                  }
                                  component={FieldBSRenderCheckbox}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {/* <div className='d-flex ms-2'>
                          {claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'initialTreatmentGiven'
                          )?.visible && (
                            <Field
                              name='initialTreatmentGiven'
                              label={
                                <div className='w-100'>
                                  {claimTypeConfiguration?.find(
                                    (f) =>
                                      f.claimTabId === ClaimTabsEnum.Info &&
                                      f.fieldName === 'initialTreatmentGiven'
                                  )?.labelName ??
                                    'What initial treatment was given?'}
                                </div>
                              }
                              validate={
                                claimTypeConfiguration?.find(
                                  (f) =>
                                    f.claimTabId === ClaimTabsEnum.Info &&
                                    f.fieldName === 'initialTreatmentGiven'
                                )?.required
                                  ? requiredField
                                  : undefined
                              }
                              component={FieldBSRenderTextHorizontal}
                              value={claim?.initialTreatmentGiven}
                              type='text'
                            />
                          )}
                        </div> */}

                        {/* </FormGroup> */}
                      </div>
                      <div className='d-flex mt-2'>
                        <div className='w-50 pe-2 mb-2 mt-2'>
                          {claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'initialTreatmentGiver'
                          )?.visible && (
                            <Field
                              name='initialTreatmentGiver'
                              label={
                                claimTypeConfiguration?.find(
                                  (f) =>
                                    f.claimTabId === ClaimTabsEnum.Info &&
                                    f.fieldName === 'initialTreatmentGiver'
                                )?.labelName ?? 'By whom?'
                              }
                              validate={
                                claimTypeConfiguration?.find(
                                  (f) =>
                                    f.claimTabId === ClaimTabsEnum.Info &&
                                    f.fieldName === 'initialTreatmentGiver'
                                )?.required
                                  ? requiredField
                                  : undefined
                              }
                              component={FieldBSRenderTextHorizontal}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex w-100 d-flex align-items-start'>
                      <div className='w-50 ms-2 pe-2 mb-2'>
                        {claimTypeConfiguration?.find(
                          (f) =>
                            f.claimTabId === ClaimTabsEnum.Info &&
                            f.fieldName === 'hospitalTreatmentNeeded'
                        )?.visible && (
                          <Field
                            name='hospitalTreatmentNeeded'
                            label={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'hospitalTreatmentNeeded'
                              )?.labelName ?? 'Was hospital treatment needed?'
                            }
                            checked={values?.hospitalTreatmentNeeded}
                            validate={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'hospitalTreatmentNeeded'
                              )?.required
                                ? requiredField
                                : undefined
                            }
                            component={FieldBSRenderCheckbox}
                            type='checkbox'
                          />
                        )}
                      </div>
                      <div className='w-50 ps-2'>
                        {claimTypeConfiguration?.find(
                          (f) =>
                            f.claimTabId === ClaimTabsEnum.Info &&
                            f.fieldName === 'hospitalTreatmentLocation'
                        )?.visible && (
                          <Field
                            name='hospitalTreatmentLocation'
                            label={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'hospitalTreatmentLocation'
                              )?.labelName ?? 'Which hospital?'
                            }
                            validate={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'hospitalTreatmentLocation'
                              )?.required
                                ? requiredField
                                : undefined
                            }
                            component={FieldBSRenderTextHorizontal}
                          />
                        )}
                      </div>
                    </div>
                    <div className='w-50 ms-2 mb-2'>
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'fatality'
                      )?.visible && (
                        <Field
                          name='fatality'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'fatality'
                            )?.labelName ?? 'Fatality?'
                          }
                          checked={values?.fatality}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'fatality'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderCheckbox}
                          type='checkbox'
                        />
                      )}
                    </div>
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'accidentLossTypeId'
                    )?.visible && (
                      <Field
                        name='accidentLossTypeId'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'accidentLossTypeId'
                          )?.labelName ?? 'Accident Loss Type'
                        }
                        options={claimCustomOptions.filter(
                          (x) =>
                            x.type === OptionTypeEnum.ClaimAccidentLossTypes
                        )}
                        optionMethod={(options: ClaimCustomOption[]) =>
                          options.map((o) => (
                            <option key={o.id} value={o.value?.toString()}>
                              {o.name}
                            </option>
                          ))
                        }
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'accidentLossTypeId'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                        component={FieldBSRenderSelectHorizontal}
                      />
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'physicianInfo.ClaimantsIme'
                    )?.visible && (
                      <Field
                        name='physicianInfo.ClaimantsIme'
                        type='text'
                        placeholder='None(See Physicians Tab to Select)'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'physicianInfo.ClaimantsIme'
                          )?.labelName ?? 'Claimant IME'
                        }
                        disabled
                        component={FieldBSRenderTextHorizontal}
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName === 'physicianInfo.ClaimantsIme'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                      />
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'physicianInfo.ClaimantsOneTimeChange'
                    )?.visible && (
                      <Field
                        name='physicianInfo.ClaimantsOneTimeChange'
                        type='text'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName ===
                                'physicianInfo.ClaimantsOneTimeChange'
                          )?.labelName ?? '1-Time Change'
                        }
                        placeholder='None(See Physicians Tab to Select)'
                        disabled
                        component={FieldBSRenderTextHorizontal}
                        validate={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.Info &&
                              f.fieldName ===
                                'physicianInfo.ClaimantsOneTimeChange'
                          )?.required
                            ? requiredField
                            : undefined
                        }
                      />
                    )}
                  </div>
                  {/* end left side */}
                </div>

                {/* right side */}
                <div className={`${styles.column}`}>
                  <div className='d-flex w-100 d-flex align-items-start '>
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'severity'
                    )?.visible && (
                      <div className='w-50 pe-2 mb-2'>
                        <fieldset className='mb-2 d-flex'>
                          <legend className=''>Severity</legend>
                          <div
                            className='w-50'
                            style={
                              severityFieldState?.submitFailed &&
                              severityFieldState?.invalid
                                ? {
                                    border: '1px solid red',
                                    borderRadius: '.25rem',
                                  }
                                : {}
                            }
                          >
                            <Field
                              name='severity'
                              type='radio'
                              label='Lost Time'
                              value='LT'
                              checked={values?.severity === 'LT'}
                              component={FieldBSRenderCheckbox}
                              onCheckChange={(val: string) => {
                                form.change('status', 'O');
                              }}
                            />
                            <Field
                              name='severity'
                              type='radio'
                              label='Medical Only'
                              value='MO'
                              checked={values?.severity === 'MO'}
                              component={FieldBSRenderCheckbox}
                              onCheckChange={(val: string) => {
                                form.change('status', 'O');
                              }}
                            />
                            <Field
                              name='severity'
                              type='radio'
                              label='Advanced Med Only'
                              value='AM'
                              checked={values?.severity === 'AM'}
                              component={FieldBSRenderCheckbox}
                              onCheckChange={(val: string) => {
                                form.change('status', 'O');
                              }}
                            />
                            <Field
                              name='severity'
                              type='radio'
                              label='Incident Only'
                              value='IO'
                              checked={values?.severity === 'IO'}
                              component={FieldBSRenderCheckbox}
                              onCheckChange={(val: string) => {
                                form.change('status', 'C');
                              }}
                            />
                          </div>
                          <div className='w-50 pt-4'>
                            <Field
                              name='controverted'
                              type='checkbox'
                              label='Controverted'
                              defaultChecked={values?.controverted}
                              component={FieldBSRenderCheckbox}
                            />
                          </div>
                        </fieldset>
                      </div>
                    )}
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.Info &&
                        f.fieldName === 'medicareStatus'
                    )?.visible && (
                      <div className='w-50 ps-2'>
                        <fieldset className='mb-3'>
                          <legend className=''>Medicare</legend>
                          <Field
                            name='medicareStatus'
                            type='text'
                            label={
                              values?.medicareQueryDate
                                ? `Medicare File sent on ${displayDateOnly(
                                    values?.medicareQueryDate
                                  )}`
                                : 'Claim has not been sent to Medicare'
                            }
                            options={medicares}
                            optionMethod={(options: MedicareItem[]) =>
                              options.map((o) => (
                                <option key={o.id} value={o.id}>
                                  {o.status}
                                </option>
                              ))
                            }
                            component={FieldBSRenderSelect}
                            validate={
                              claimTypeConfiguration?.find(
                                (f) =>
                                  f.claimTabId === ClaimTabsEnum.Info &&
                                  f.fieldName === 'medicareStatus'
                              )?.required
                                ? requiredField
                                : undefined
                            }
                          />
                        </fieldset>
                      </div>
                    )}
                  </div>
                  <div className='d-flex w-100 d-flex align-items-start '>
                    <div className='w-50 pe-2'>
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'claimSubTypeId'
                      )?.visible && (
                        <Field
                          name='claimSubTypeId'
                          label='Claim Sub Type'
                          options={claimSubTypes}
                          optionMethod={(options: ClaimSubType[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id}>
                                {o.name}
                              </option>
                            ))
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'claimSubTypeId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderSelectHorizontal}
                        />
                      )}

                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'policyFormId'
                      )?.visible && (
                        <Field
                          name='policyFormId'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'policyFormId'
                            )?.labelName ?? 'Policy Form'
                          }
                          options={claimCustomOptions.filter(
                            (x) =>
                              x.type === OptionTypeEnum.PolicyForm &&
                              (x.claimTypeId === null ||
                                x.claimTypeId === claimType)
                          )}
                          optionMethod={(options: ClaimCustomOption[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id?.toString()}>
                                {o.name}
                              </option>
                            ))
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'policyFormId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderSelectHorizontal}
                        />
                      )}

                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'policyId'
                      )?.visible && (
                        <Field
                          name='policyId'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'policyId'
                            )?.labelName ?? 'Policy Number'
                          }
                          options={policies}
                          onChange={(id: number) => {
                            handlePolicyChange(values?.employerId ?? 0, id);
                          }}
                          optionMethod={(options: EmployerPolicy[]) =>
                            options.map((o) => (
                              <option key={o.policyId} value={o.policyId}>
                                {o.policyNumber}
                              </option>
                            ))
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'policyId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderSelectHorizontal}
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'locationId'
                      )?.visible && (
                        <Field
                          name='locationId'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'locationId'
                            )?.labelName ?? 'Location'
                          }
                          options={locations}
                          component={FieldBSRenderSelectHorizontalSearchable}
                          optionMethod={(options: Location[]) =>
                            options.map((o) => ({
                              value: o.locationId,
                              label:
                                o.locationName +
                                (' ' + o.address
                                  ? ' - ' +
                                    o.address?.address1 +
                                    ', ' +
                                    o.address?.city +
                                    ', ' +
                                    o.address?.state +
                                    ' ' +
                                    o.address?.zip
                                  : ''),
                            }))
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'locationId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'classCodeId'
                      )?.visible && (
                        <Field
                          name='classCodeId'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'classCodeId'
                            )?.labelName ?? 'Job Class'
                          }
                          options={policyClassCodes}
                          optionMethod={(options: PolicyClassCode[]) =>
                            options.map((o) => (
                              <option
                                key={o?.classCode?.classCodeId}
                                value={o?.classCode?.classCodeId}
                              >
                                {o.classCode?.classCode +
                                  ' ' +
                                  o.classCode?.description}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelectHorizontal}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'classCodeId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'departmentId'
                      )?.visible && (
                        <Field
                          name='departmentId'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'departmentId'
                            )?.labelName ?? 'Sub Agency'
                          }
                          options={departments}
                          optionMethod={(options: Department[]) =>
                            options.map((o) => ({
                              value: o.departmentId,
                              label: o.clientDeptId + '-' + o.departmentName,
                            }))
                          }
                          component={FieldBSRenderSelectHorizontalSearchable}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'departmentId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'shiftId'
                      )?.visible && (
                        <Field
                          name='shiftId'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'shiftId'
                            )?.labelName ?? 'Shift'
                          }
                          options={shifts}
                          optionMethod={(options: Shift[]) =>
                            options.map((o) => (
                              <option key={o.shiftId} value={o.shiftId}>
                                {o.shiftName}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelectHorizontal}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'shiftId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                        />
                      )}
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'severityCodeId'
                      )?.visible && (
                        <Field
                          name='severityCodeId'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'severityCodeId'
                            )?.labelName ?? 'Severity Code'
                          }
                          options={claimCustomOptions.filter(
                            (x) =>
                              x.type === OptionTypeEnum.SeverityCode &&
                              (x.claimTypeId === null ||
                                x.claimTypeId === claimType)
                          )}
                          optionMethod={(options: ClaimCustomOption[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id?.toString()}>
                                {o.name}
                              </option>
                            ))
                          }
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'severityCodeId'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                          component={FieldBSRenderSelectHorizontal}
                        />
                      )}
                    </div>
                    <div className='w-50 ps-2'>
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.Info &&
                          f.fieldName === 'ncciOpen'
                      )?.visible && (
                        <Field
                          name='ncciOpen'
                          type='checkbox'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'ncciOpen'
                            )?.labelName ?? 'NCCI Reported'
                          }
                          checked={!!values?.ncciOpen}
                          component={FieldBSRenderCheckbox}
                          validate={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.Info &&
                                f.fieldName === 'ncciOpen'
                            )?.required
                              ? requiredField
                              : undefined
                          }
                        />
                      )}
                    </div>
                  </div>
                  {claimTypeConfiguration?.find(
                    (f) =>
                      f.claimTabId === ClaimTabsEnum.Info &&
                      f.fieldName === 'excessInformationGrid'
                  )?.visible && (
                    <div className='alert alert-dark' role='alert'>
                      <p className='fw-bold'>Excess Policy Information</p>
                      <div className={styles.excessInformationGrid}>
                        <p className={styles.excJustifyEnd}>Excess Carrier</p>
                        <p className={styles.excJustifyStart}>
                          {claimExcessPolicy?.excessCarrierName}
                        </p>
                        <p className={styles.excJustifyEnd}></p>
                        <p className={styles.excJustifyStart}></p>

                        <p className={styles.excJustifyEnd}>Policy Number</p>
                        <p className={styles.excJustifyStart}>
                          {claimExcessPolicy?.carrierPolicyNo ?? ''}
                        </p>
                        <p className={styles.excJustifyEnd}>Start Date</p>
                        <p className={styles.excJustifyStart}>
                          {displayDateOnly(claimExcessPolicy?.startDate ?? '')}
                        </p>

                        <p className={styles.excJustifyEnd}>Treaty Year</p>
                        <p className={styles.excJustifyStart}>
                          {claimExcessPolicy?.treatyYear}
                        </p>
                        <p className={styles.excJustifyEnd}>End Date</p>
                        <p className={styles.excJustifyStart}>
                          {displayDateOnly(claimExcessPolicy?.endDate ?? '')}
                        </p>

                        <p className={styles.excJustifyEnd}>Premium</p>
                        <p className={styles.excJustifyStart}>{premium}</p>
                        <p className={styles.excJustifyEnd}>Default SIR</p>
                        <p className={styles.excJustifyStart}>{defaultSir}</p>

                        <p className={styles.excJustifyEnd}>Audited?</p>
                        <p className={styles.excJustifyStart}>
                          <input
                            id='audited'
                            className='form-check-input'
                            type='checkbox'
                            checked={audited}
                            readOnly
                          ></input>
                        </p>
                        <p className={styles.excJustifyEnd}>
                          Corridor Deductible
                        </p>
                        <p className={styles.excJustifyStart}>
                          {corridorDeductible}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`${styles.claimDetailBottomButtons} bg-secondary p-2`}
              >
                <div className=''>
                  <Button
                    variant='secondary'
                    type='button'
                    onClick={() => setShowClaimantForm(true)}
                  >
                    Edit Claimant Info
                  </Button>
                  <Button
                    className='ms-1'
                    variant='secondary'
                    type='button'
                    onClick={() => setShowEmployerSelect(true)}
                  >
                    Edit Employer Info
                  </Button>
                </div>
                <div className={styles.saveCancelBtnGrp}>
                  <Button type='submit' variant='secondary'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    className='ms-2'
                    variant='secondary'
                    type='button'
                    onClick={() => {
                      dispatch(clearSelectedClaim());
                      navigate('/');
                      form.restart();
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <BlankModal
        show={showBlankModal}
        setShow={setShowBlankModal}
        message={blankModalMessage}
      />
    </div>
  );
}
