import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  ExpandedState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import {
  FaCaretDown,
  FaCaretRight,
  FaCircle,
  FaEdit,
  FaEye,
} from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';
import LogTable from './LogTable';

export default function EdiLogTable({
  data,
  openEditModal,
}: {
  data: EdiTransaction[];
  openEditModal: (t: EdiTransaction) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<EdiTransaction>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Link to={`/claims/${row.original.claimno}`}>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                className='button-con-text'
              >
                <FaEye /> Claim
              </Button>
            </Link>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                openEditModal(row.original);
              }}
            >
              <FaEdit />
            </Button>
          </div>
        );
      },
    },
    {
      header: '',
      id: 'expander',
      size: 35,
      cell: ({ row }) => (
        <div>
          {row.getCanExpand() ? (
            <Button
              size='sm'
              variant='outline-primary'
              title='Expand'
              onClick={row.getToggleExpandedHandler()}
            >
              {row.getIsExpanded() ? <FaCaretDown /> : <FaCaretRight />}
            </Button>
          ) : (
            <FaCircle />
          )}
        </div>
      ),
    },
    {
      header: 'XCN',
      accessorKey: 'claim.ediClaimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimno',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'DOI',
      accessorKey: 'claim.injuryDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      size: 100,
      cell: (d) => displayDateOnly(d.row.original.claim?.injuryDate ?? ''),
    },
    {
      header: 'Claimant Name',
      accessorFn: (d) =>
        d.claimant ? `${d.claimant.firstName} ${d.claimant.lastName}` : '',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Form',
      accessorKey: 'form',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Maint Type Code',
      accessorKey: 'maintTypeCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'logDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      size: 100,
      cell: (d) => displayDateOnly(d.row.original.logDate ?? ''),
    },
    {
      header: 'Creator',
      accessorKey: 'logCreator',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorKey: 'logStatusString',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Error Message',
      accessorKey: 'notes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 300,
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      expanded,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
    getRowCanExpand: (row) =>
      row.original.ediTransactionLogs !== null &&
      row.original.ediTransactionLogs.length > 0,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      createChildTable={LogTable}
      subRowProp={'ediTransactionLogs'}
      selectableRow={true}
    />
  );
}
