import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import styles from './index.module.css';
import { Department } from '../../ApiTypes/Department';

export default function DepartmentTable({
  departments,
  handleEdit,
  handleDelete,
}: {
  handleEdit: (department: Department | null) => void;
  handleDelete: (department: Department) => void;
  departments: Department[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => departments, [departments]);

  const columnData: ColumnDef<Department>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      maxSize: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-center gap1Rem'>
            <Button
              className='w-25'
              type='button'
              size='sm'
              variant='primary'
              onClick={() => {
                handleEdit(d.row.original);
              }}
              title='Edit'
            >
              <FaEdit />
            </Button>
            <Button
              className='w-25'
              type='button'
              size='sm'
              variant='danger'
              onClick={() => {
                handleDelete(d.row.original);
              }}
              title='Delete'
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Id',
      accessorFn: (d) => `${d.departmentId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Department Name',
      accessorKey: 'departmentName',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Client Dept. Id',
      accessorKey: 'clientDeptId',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Import Id',
      accessorKey: 'importDeptId',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
