import React, { useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';
import { ClaimFinancialHistoryDetailObj } from '../../ApiTypes/HistoryObjects';

export default function ClaimFinancialHistoryTable({
  data,
}: {
  data: ClaimFinancialHistoryDetailObj[];
}) {
  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ClaimFinancialHistoryDetailObj>[] = [
    {
      header: 'As Of Date',
      accessorKey: 'asOfDate',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Med Reserves',
      accessorFn: (d) => `${d.medReserves}`,

      cell: (d) => accounting.formatMoney(d.row.original.medReserves ?? 0),
    },
    {
      header: 'Ind Reserves',
      accessorFn: (d) => `${d.indReserves}`,

      cell: (d) => accounting.formatMoney(d.row.original.indReserves ?? 0),
    },
    {
      header: 'Exp Reserves',
      accessorFn: (d) => `${d.expReserves}`,

      cell: (d) => accounting.formatMoney(d.row.original.expReserves ?? 0),
    },
    {
      header: 'Leg Reserves',
      accessorFn: (d) => `${d.legReserves}`,

      cell: (d) => accounting.formatMoney(d.row.original.legReserves ?? 0),
    },
    {
      header: 'Total Reserves',
      accessorFn: (d) => `${d.totalReserves}`,

      cell: (d) => accounting.formatMoney(d.row.original.totalReserves ?? 0),
    },
    {
      header: 'Med Paid',
      accessorFn: (d) => `${d.medPaid}`,

      cell: (d) => accounting.formatMoney(d.row.original.medPaid ?? 0),
    },
    {
      header: 'Ind Paid',
      accessorFn: (d) => `${d.indPaid}`,

      cell: (d) => accounting.formatMoney(d.row.original.indPaid ?? 0),
    },
    {
      header: 'Exp Paid',
      accessorFn: (d) => `${d.expPaid}`,

      cell: (d) => accounting.formatMoney(d.row.original.expPaid ?? 0),
    },
    {
      header: 'Leg Paid',
      accessorFn: (d) => `${d.legPaid}`,
      cell: (d) => accounting.formatMoney(d.row.original.legPaid ?? 0),
    },
    {
      header: 'Total Paid',
      accessorFn: (d) => `${d.totalPaid}`,
      cell: (d) => accounting.formatMoney(d.row.original.totalPaid ?? 0),
    },
    {
      header: 'Med Incurred',
      accessorFn: (d) => `${d.medIncurred}`,
      cell: (d) => accounting.formatMoney(d.row.original.medIncurred ?? 0),
    },
    {
      header: 'Ind Incurred',
      accessorFn: (d) => `${d.indIncurred}`,
      cell: (d) => accounting.formatMoney(d.row.original.indIncurred ?? 0),
    },
    {
      header: 'Exp Incurred',
      accessorFn: (d) => `${d.expIncurred}`,
      cell: (d) => accounting.formatMoney(d.row.original.expIncurred ?? 0),
    },
    {
      header: 'Leg Incurred',
      accessorFn: (d) => `${d.legIncurred}`,
      cell: (d) => accounting.formatMoney(d.row.original.legIncurred ?? 0),
    },
    {
      header: 'Total Incurred',
      accessorFn: (d) => `${d.totalIncurred}`,
      cell: (d) => accounting.formatMoney(d.row.original.totalIncurred ?? 0),
    },
  ];
  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {},
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={false}
      selectableRow={true}
    />
  );
}
