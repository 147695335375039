import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { VwDiaryReport } from '../../ApiTypes/VwDiaryReport';
import { displayDateOnly } from '../../Utils';
import { Link } from 'react-router-dom';

export default function DiaryTable({ reports }: { reports: VwDiaryReport[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => reports, [reports]);

  const columnData: ColumnDef<VwDiaryReport>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      maxSize: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Link to={`/claims/${d.row.original.claimNo}`}>
              <Button
                type='button'
                size='sm'
                variant='primary'
                title='View Claim'
              >
                <FaEye />
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Adjuster Name',
      accessorKey: 'adjusterName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim',
      accessorKey: 'claimNo',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'DOB',
      accessorKey: 'dob',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dob ?? ''),
    },
    {
      header: 'Injury Date',
      accessorKey: 'injuryDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.injuryDate ?? ''),
    },
    {
      header: 'Severity',
      accessorKey: 'severity',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Employer',
      accessorKey: 'employer',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Adjuster Diary Date',
      accessorKey: 'adjusterFollowUpDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.adjusterFollowUpDate ?? ''),
    },
    {
      header: 'Supervisor Diary Date',
      accessorKey: 'supervisorFollowUpDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.supervisorFollowUpDate ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
