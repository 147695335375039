import React, { useMemo } from 'react';
import {
  useReactTable,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { LinkedClaim } from '../../ApiTypes/LinkedClaim';

export default function LinkedClaimTable({
  data,
  handleSelect,
}: {
  data: LinkedClaim[];
  handleSelect?: (linkedClaim: LinkedClaim) => void;
}) {
  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<LinkedClaim>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      maxSize: 75,
      cell: (d) => {
        if (handleSelect) {
          return (
            <Button
              type='button'
              variant='danger'
              size='sm'
              onClick={() => handleSelect(d.row.original)}
            >
              Remove
            </Button>
          );
        } else {
          return <div />;
        }
      },
    },
    {
      header: 'Claimant',
      accessorFn: (d) => `${d?.claimant?.firstName} ${d?.claimant?.lastName}`,
    },
    {
      header: 'Old Claim No',
      accessorFn: (d) => d.originalClaimno,
    },
    {
      header: 'New Claim No',
      accessorFn: (d) => d.newClaimno,
    },
  ];

  const columns = useMemo(() => columnData, [data]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {},
    initialState: {},
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={false}
        showPagination={false}
        noEmptyRows={true}
        selectableRow={true}
      />
    </div>
  );
}
