import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { FaFolderOpen, FaPlusCircle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import { useParams } from 'react-router-dom';
import { ExcessCarrierPolicy } from '../../ApiTypes/ExcessCarrierPolicy';
import ExcessCarrierApi from '../../Api/ExcessCarrierApi';
import { toast } from 'react-toastify';
import ExcessPolicyTable from './ExcessPolicyTable';
import ExcessCarrierPolicyApi from '../../Api/ExcessCarrierPolicyApi';
import { Button } from 'react-bootstrap';
import AddEditExcessPolicy from '../PoliciesMaintenance/AddEditExcessPolicy';

export default function ExcessPolicies() {
  const { excessCarrierId } = useParams();
  const [policies, setPolicies] = useState<ExcessCarrierPolicy[]>([]);

  const [selectedPolicy, setSelectedPolicy] =
    useState<ExcessCarrierPolicy | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  useEffect(() => {
    getPolicies();
  }, [excessCarrierId]);

  const getPolicies = () => {
    if (excessCarrierId) {
      ExcessCarrierApi.getForCarrier(+excessCarrierId)
        .then((res) => {
          setPolicies(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get policies');
        });
    }
  };

  const handleDelete = (policy: ExcessCarrierPolicy) => {
    return ExcessCarrierPolicyApi.delete(policy.excessCarrierPolicyId!)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getPolicies();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete policy');
      });
  };

  const handleEdit = (policy: ExcessCarrierPolicy) => {
    setSelectedPolicy(policy);
    setShowEdit(true);
  };

  const setNull = () => {
    setSelectedPolicy(null);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Excess Policies</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={() => {
            setSelectedPolicy(null);
            setShowEdit(true);
          }}
        >
          <FaPlusCircle /> New
        </Button>
      </div>
      <ExcessPolicyTable
        data={policies}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
      <>
        {showEdit && (
          <AddEditExcessPolicy
            show={showEdit}
            setShow={setShowEdit}
            excessPolicyId={selectedPolicy?.excessCarrierPolicyId ?? 0}
            excessCarrierId={+excessCarrierId!}
            refreshData={getPolicies}
            setNull={setNull}
          />
        )}
      </>
    </PageScaffold>
  );
}
