import React, { useState, useMemo } from 'react';
import { displayDateOnly } from '../../Utils';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { Wc1file } from '../../ApiTypes/Wc1file';

type Doc = { id: string; fileUrl: string; dateAdded: string };

export default function Wc1Documents({
  values,
  files,
}: {
  values: any;
  files: Wc1file[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => files, [files]);

  const columnData: ColumnDef<Wc1file>[] = [
    {
      header: 'Id',
      accessorFn: (d) => `${d.id}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'File Url',
      accessorFn: (d) => d.fileUrl,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: ({ row }) => {
        return (
          <a
            href={`${row.original.fileUrl}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button type='button' variant='outline-primary' size='sm'>
              {row.original.fileUrl}
            </Button>
          </a>
        );
      },
    },
    {
      header: 'Date Added',
      accessorFn: (d) => d.dateAdded,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: ({ row }) =>
        row.original.dateAdded ? displayDateOnly(row.original.dateAdded) : '',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
      />
    </div>
  );
}
