import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ClaimCountResponse } from '../ApiTypes/ClaimCountResponse';
import { EntityUser } from '../ApiTypes/EntityUser';
import { UserHistory } from '../ApiTypes/UserHistory';
import { VwUser } from '../ApiTypes/VwUser';
import { AddUpdateUserRequest } from '../ApiTypes/AddUpdateUserRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { UpdateEmailRequest } from '../ApiTypes/UpdateEmailRequest';

class UserApi {
  static getUsersList() {
    return axios.get<EntityUser[]>(
      `${API_URL}/api/users/list`,
      getAuthHeader()
    );
  }
  static getUserHistory(userId: string, insurCoId: number, count: number) {
    return axios.get<UserHistory[]>(
      `${API_URL}/api/user/${userId}/history?insurCoId=${insurCoId}&count=${count}`,
      getAuthHeader()
    );
  }
  static addUserHistory(history: UserHistory) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/user/history`,
      history,
      getAuthHeader()
    );
  }
  static getUserClaimCounts() {
    return axios.get<ClaimCountResponse>(
      `${API_URL}/api/user/claimcounts`,
      getAuthHeader()
    );
  }
  static getUsersForMaintenance(active: boolean) {
    return axios.get<VwUser[]>(
      `${API_URL}/api/users/maintenance?active=${active}`,
      getAuthHeader()
    );
  }
  static getVwUsersForSupervisor(userId: string) {
    return axios.get<VwUser[]>(
      `${API_URL}/api/users/supervisor?userId=${userId}`,
      getAuthHeader()
    );
  }
  static getById(userId: string) {
    return axios.get<EntityUser>(
      `${API_URL}/api/users/${userId}`,
      getAuthHeader()
    );
  }
  static addUpdateUserWithSiteFunctionIdsAndAccess(
    request: AddUpdateUserRequest
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/users`,
      request,
      getAuthHeader()
    );
  }
  static updateEmail(request: UpdateEmailRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/users/email`,
      request,
      getAuthHeader()
    );
  }
  static updateThemeId(userId: string, themeId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/users/${userId}/themeid/${themeId}`,
      null,
      getAuthHeader()
    );
  }
}

export default UserApi;
