import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { InsCoLetterLog } from '../../../ApiTypes/InsCoLetterLog';
import { displayDateOnly } from '../../../Utils';
import { FaEye } from 'react-icons/fa';
import DocumentsApi from '../../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../Reducers/Store';
import {
  setShowDocView,
  setDocViewFileDownload,
} from '../../../Actions/DocViewActions';
import { FileDownload } from '../../../Types/FileDownLoad';

export default function HistoryTable({
  letterLogs,
}: {
  letterLogs: InsCoLetterLog[];
}) {
  const dispatch = useAppDispatch();
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => letterLogs, [letterLogs]);

  const setShow = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const handleViewClick = (log: InsCoLetterLog) => {
    if (log.claimNo && log.docId) {
      DocumentsApi.getScanDocFile(log.docId!)
        .then((res) => {
          setFileDownload(res.data);
          setShow(true);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to document to view');
        });
    } else {
      toast.error('Can not view this document');
    }
  };

  const columnData: ColumnDef<InsCoLetterLog>[] = [
    {
      header: 'Log Id',
      accessorFn: (d) => `${d.insCoLetterLogId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Letter Name',
      accessorFn: (d) => d.letter?.letterName ?? '',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Comments',
      accessorFn: (d) => d.comments ?? '',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Logged',
      accessorKey: 'dateLogged',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateLogged ?? ''),
    },
    {
      header: 'User',
      accessorKey: 'userNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      size: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View'
              onClick={() => {
                handleViewClick(d.row.original);
              }}
            >
              <FaEye />
            </Button>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
      />
    </div>
  );
}
