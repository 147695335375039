import React, { useState, useMemo, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FaCheck, FaEdit, FaSearch } from 'react-icons/fa';
import { Employer } from '../../ApiTypes/Employer';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import BSSelect from '../Common/BSSelect';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import styles from './index.module.css';
import {
  fetchEmployerDepartments,
  fetchEmployerList,
  fetchEmployerPolicies,
} from '../../Actions/EmployerActions';
import {
  updateCurrentEmployerInReducer,
  updateCurrentInsuranceCompanyInReducer,
} from '../../Actions/CurrentClaimActions';
import { toast } from 'react-toastify';

export default function EmployerSelect({
  show,
  setShow,
  employer,
  setEmployerToEdit,
  allowEdit = true,
  allowNew = true,
  returnEmployer,
}: {
  show: boolean;
  setShow: (value: boolean) => void;
  setEmployerToEdit: (employer: Employer | null) => void;
  employer?: Employer;
  allowEdit?: boolean;
  allowNew?: boolean;
  returnEmployer?: (employer: Employer) => void;
}) {
  const dispatch = useAppDispatch();

  const { employers } = useAppSelector((state) => state.employer);
  const { insuranceCompanies } = useAppSelector((state) => state.reference);

  const [accountNumberValue, setAccountNumberValue] = useState<string>('');

  const [fetching, setFetching] = useState(false);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    if (employer) {
      setAccountNumberValue(employer.accountNumber);
      handleAccountChange(employer.accountNumber);
    }
  }, [employer]);

  const handleAccountChange = (accountNumber: string) => {
    setAccountNumberValue(accountNumber);
    if (accountNumber) {
      setFetching(true);
      dispatch(fetchEmployerList({ accountNumber }))
        .then((res) => setFetching(false))
        .catch((err) => setFetching(false));
    }
  };

  const handleEmployerSelect = (emp: Employer) => {
    const insurCo = insuranceCompanies.find(
      (f) => f.accountnumber === emp.accountNumber
    );
    dispatch(updateCurrentEmployerInReducer(emp));
    dispatch(fetchEmployerDepartments(emp.employerId!));
    dispatch(fetchEmployerPolicies(emp.employerId!));
    if (insurCo) {
      dispatch(updateCurrentInsuranceCompanyInReducer(insurCo));
    }

    setShow(false);
  };

  const tableData = useMemo(() => employers, [employers]);

  const columnData: ColumnDef<Employer>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      maxSize: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              onClick={() => {
                if (returnEmployer) {
                  returnEmployer(d.row.original);
                } else {
                  handleEmployerSelect(d.row.original);
                }
              }}
              title='Select'
            >
              <FaCheck />
            </Button>
            {allowEdit && (
              <Button
                type='button'
                size='sm'
                variant='secondary'
                title='Edit'
                onClick={() => setEmployerToEdit(d.row.original)}
              >
                <FaEdit />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      header: 'Account',
      accessorFn: (d) => d.accountNumber,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Employer Name',
      accessorKey: 'name',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Client Employer Id',
      accessorKey: 'clientEmployerId',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Address',
      accessorKey: 'address',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'City',
      accessorKey: 'city',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'State',
      accessorKey: 'state',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Zip',
      accessorKey: 'zip',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [insuranceCompanies]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Modal
      centered
      show={show}
      size='xl'
      onHide={() => setShow(false)}
      dialogClassName={styles.largeModal}
      aria-labelledby='Employer-Select-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Employer-Select-Form-modal'
        >
          <FaSearch className='pe-1' /> Employer List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='w-100'>
          <div className='d-flex justify-content-start align-items-center'>
            <div className={styles.width33}>
              <BSSelect
                name='accountNumber'
                label='Account'
                onChange={handleAccountChange}
                defaultValue={accountNumberValue}
                options={[...insuranceCompanies].sort((a, b) =>
                  a.shortname!.localeCompare(b.shortname!)
                )}
                optionMethod={(options: InsuranceCompany[]) =>
                  options.map((o) => (
                    <option key={o.accountnumber!} value={o.accountnumber!}>
                      {`${o.accountnumber} - ${o.shortname}`}
                    </option>
                  ))
                }
              />
            </div>
            <div className={`${styles.width33} ms-2`}>
              {fetching && (
                <Spinner
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </div>
            <div className={styles.width33}>
              {allowNew && (
                <Button
                  className='w-25'
                  type='button'
                  size='sm'
                  onClick={() => {
                    if (!accountNumberValue) {
                      toast.info('Select an account for the new employer');
                      return;
                    }
                    setEmployerToEdit({
                      accountNumber: accountNumberValue,
                    } as Employer);
                  }}
                >
                  Add New
                </Button>
              )}
            </div>
          </div>
          <PaginatedTable
            table={table}
            columnResizeMode='onChange'
            showFilters={true}
            selectableRow={true}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
