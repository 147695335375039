import React, { useState, useMemo, useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  FilterFn,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { ClaimNote } from '../../../ApiTypes/ClaimNote';
import { Button } from 'react-bootstrap';
import { FaEdit, FaFile, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import styles from './index.module.css';
import DocumentsApi from '../../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import { useDocViewModal } from '../../DocViewModal/useDocViewModal';
import PdfModal from '../../DocViewModal/DocViewModal';
import { displayDateOnly } from '../../../Utils';

export default function NotesTable({
  notes,
  getClaimNotes,
  show,
  setShow,
  setSelectedNote,
  selectedNote,
  deleteNote,
  setShowMoveClaimNoteModal,
  setNoteToDelete,
  setShowDeleteConfirm,
}: {
  notes: ClaimNote[];
  getClaimNotes: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
  setShowMoveClaimNoteModal: (show: boolean) => void;
  setSelectedNote: (note: ClaimNote | null) => void;
  selectedNote: ClaimNote | null;
  deleteNote: (note: ClaimNote) => void;
  setShowDeleteConfirm: (show: boolean) => void;
  setNoteToDelete: (noteId: number | null) => void;
}) {
  let { claimNumber } = useParams();
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => notes, [notes]);

  const today = displayDateOnly(new Date());

  const handleViewDoc = (note: ClaimNote) => {
    if (note.docId) {
      DocumentsApi.getScanDocFile(note.docId)
        .then((res) => {
          setShowDocViewModal(true);
          setFileDownload(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get document to view');
        });
    }
  };

  const noteTextFilter: FilterFn<ClaimNote> = (
    row,
    columnId,
    value,
    addMeta
  ) => {
    const item = row.getValue(columnId) as string;
    const it = JSON.stringify(item.toLowerCase())
      .replace(/^"|"$/g, '')
      .replace(/\\r+|\\n+|\\t/g, '');

    return it.includes(value.toLowerCase());
  };

  const columnData: ColumnDef<ClaimNote>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 55,
      cell: (d) => {
        return (
          <div
            className={`${styles.actionColumn} ${
              d.row.original.noteId === selectedNote?.noteId ? 'orange' : ''
            }`}
          >
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Edit/View'
              onClick={() => {
                setSelectedNote(d.row.original);
                setShow(true);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='secondary'
              title='Move To New Claim'
              onClick={() => {
                setSelectedNote(d.row.original);
                setShowMoveClaimNoteModal(true);
              }}
            >
              Move
            </Button>
            {displayDateOnly(d.row.original.noteDate ?? '') == today && (
              <Button
                type='button'
                size='sm'
                variant='danger'
                title='delete'
                onClick={() => {
                  setNoteToDelete(d.row.original.noteId);
                  setShowDeleteConfirm(true);
                }}
              >
                <FaTrash />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      header: 'Date',
      accessorKey: 'noteDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.noteDate ?? ''),
      maxSize: 100,
    },
    {
      header: 'User',
      accessorKey: 'userId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Note Text',
      accessorFn: (d) => d.noteText,
      sortingFn: 'alphanumeric',
      filterFn: noteTextFilter,
      cell: (d) =>
        d.row.original.noteText
          ? d.row.original.noteText.replace(
              /<\/?[^>]+(>|$)|&(nbsp|amp|quot);/g,
              ' '
            )
          : '',
      size: 300,
    },
    {
      header: 'Note Type',
      accessorFn: (d) => d.claimNoteType?.name ?? '',
      sortingFn: 'alphanumeric',
      size: 150,
      filterFn: 'equals',
    },
    {
      header: 'Doc',
      id: 'doc',
      enableColumnFilter: false,
      enableSorting: false,
      size: 50,
      cell: ({ row }) => {
        if (row.original.docId) {
          return (
            <Link
              to={`/scandocs/${row.original.docId}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                // onClick={() => handleViewDoc(row.original)}
              >
                <FaFile />
              </Button>
            </Link>
          );
        } else {
          return '';
        }
      },
    },
  ];

  const columns = useMemo(() => columnData, [selectedNote]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <div className='py-2 d-flex justify-content-center'>
        <Button
          variant='primary'
          size='sm'
          onClick={() => {
            setSelectedNote(null);
            setShow(true);
          }}
          disabled={claimNumber === undefined}
        >
          <div className='button-icon-text'>
            <FaPlusCircle /> New Note
          </div>
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        // selectableRow={true}
        highlightRow={true}
        setSelectedItem={setSelectedNote}
      />
      <PdfModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </div>
  );
}
