import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { Employer } from '../../ApiTypes/Employer';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import styles from './index.module.css';

export default function EmployerTable({
  employers,
  handleEdit,
}: {
  handleEdit: (employer: Employer | null) => void;
  employers: Employer[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => employers, [employers]);

  const columnData: ColumnDef<Employer>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      maxSize: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              className='w-25'
              type='button'
              size='sm'
              variant='primary'
              onClick={() => {
                handleEdit(d.row.original);
              }}
              title='Edit'
            >
              <FaEdit />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Account',
      accessorFn: (d) => d.accountNumber,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      size: 100,
    },
    {
      header: 'Employer Name',
      accessorKey: 'name',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Tax Id',
      accessorKey: 'taxid',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
