import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { displayDateOnly } from '../../../Utils';
import { Button } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styles from './index.module.css';
import { ClaimLitigation } from '../../../ApiTypes/ClaimLitigation';
import LitigationApi from '../../../Api/LitigationApi';
import AddEditLitigationModal from './AddEditLitigationModal';

export default function LitigationTable({
  data,
  claimNumber,
  getClaimLitigations,
}: {
  data: ClaimLitigation[];
  claimNumber: string;
  getClaimLitigations: () => void;
}) {
  const handleEdit = (cs: ClaimLitigation) => {
    setSelectedLitigation(cs);
    setShowAddEdit(true);
  };

  const handleDelete = (cs: ClaimLitigation) => {
    return LitigationApi.deleteClaimLitigation(cs.id)
      .then((res) => {
        if (res.data.success) {
          getClaimLitigations();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete Litigation');
      });
  };

  const [selectedLitigation, setSelectedLitigation] =
    useState<ClaimLitigation | null>(null);

  const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ClaimLitigation>[] = [
    {
      header: '',
      id: 'actions',
      enableColumnFilter: false,
      enableSorting: false,
      size: 100,
      cell: ({ row }) => {
        return (
          <div className={`${styles.actionButtons}`}>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Edit'
              onClick={() => {
                handleEdit(row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Case Name',
      accessorKey: 'caseName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Case Number',
      accessorKey: 'caseNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Appeal Result',
      accessorFn: (d) =>
        d.appealResultId !== null && d.appealResult !== null
          ? `${d.appealResult.name}`
          : null,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) =>
        d.row.original.appealResultId !== null &&
        d.row.original.appealResult !== null
          ? d.row.original.appealResult.name
          : '',
    },
    {
      header: 'MSJ Result',
      accessorFn: (d) =>
        d.msjresultId !== null && d.msjresult !== null
          ? `${d.msjresult.name}`
          : null,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) =>
        d.row.original.msjresultId !== null && d.row.original.msjresult !== null
          ? d.row.original.msjresult.name
          : '',
    },
    {
      header: 'Plaintiff',
      accessorKey: 'plaintiffName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Filed Date',
      accessorKey: 'filedDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
  ];
  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <div className='d-flex justify-content-center align-items-center mb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={() => {
            setSelectedLitigation(null);
            setShowAddEdit(true);
          }}
        >
          <FaPlusCircle /> Add New
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
      />
      <AddEditLitigationModal
        show={showAddEdit}
        setShow={setShowAddEdit}
        claimLitigation={selectedLitigation ?? null}
        setSelectedLitigation={setSelectedLitigation}
        claimNumber={claimNumber}
        getClaimLitigations={getClaimLitigations}
      />
    </div>
  );
}
