import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, Spinner } from 'react-bootstrap';
import {
  FaSearch,
  FaFileAlt,
  FaSync,
  FaIdCard,
  FaClipboard,
  FaCheck,
  FaEdit,
  FaFolderOpen,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import { fetchClaimByClaimNumber } from '../../Actions/ClaimActions';
import { updateUserPriorityClaim } from '../../Actions/ReferenceActions';
import ClaimApi from '../../Api/ClaimApi';
import Wc1Api from '../../Api/Wc1Api';
import WcPanelApi from '../../Api/WcPanelApi';
import { Claim } from '../../ApiTypes/Claim';
import { Employer } from '../../ApiTypes/Employer';
import { Wc1 } from '../../ApiTypes/Wc1';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { FileDownload } from '../../Types/FileDownLoad';
import {
  calculateAge,
  calculateAgeAtInjury,
  displayDateOnly,
} from '../../Utils/index';
import { formatSSN } from '../../Utils/InputFormatters';
import ClaimantForm from '../ClaimantForm/ClaimantForm';
import BlankModal from '../Common/BlankModal';
import { useBlankModal } from '../Common/useBlankModal';
import EditWc1 from '../EditWc1/EditWc1';
import EmployerAddEdit from '../EmployerAddEdit/EmployerAddEdit';
import EmployerSelect from '../EmployerSelect/EmployerSelect';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import PharmacyCardsModal from '../PharmacyCards/PharmacyCardsModal';
import SharedFilesModal from '../SecureShare/SharedFilesModal';
import Wc1ReportOptions from '../WC1/Wc1ReportOptions';
import ClaimActionButton from '../ClaimDetailTop/ClaimActionButton';
import ClaimReportButton from '../ClaimDetailTop/ClaimReportButton';
import styles from './index.module.css';
import { useParams } from 'react-router-dom';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import {
  ClaimTypeConfiguration,
  ClaimTypes,
  ClaimTypesString,
} from '../../ApiTypes/ClaimTypeConfiguration';
import TextMessageModal from '../Texting/TextMessageModal';
import { MdMessage } from 'react-icons/md';

export default function ClaimDetailTopCustom({
  showClaimantForm,
  setShowClaimantForm,
  showEmployerSelect,
  setShowEmployerSelect,
  claimType,
}: {
  showClaimantForm: boolean;
  setShowClaimantForm: (show: boolean) => void;
  showEmployerSelect: boolean;
  setShowEmployerSelect: (show: boolean) => void;
  claimType: number;
}) {
  const dispatch = useAppDispatch();
  const { claimNumber } = useParams();
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();
  const [refreshing, setRefreshing] = useState(false);

  const [claimantInfo, setClaimantInfo] = useState<string>('');
  const [employerInfo, setEmployerInfo] = useState<string>('');
  const [showEmployerAddEdit, setShowEmployerAddEdit] =
    useState<boolean>(false);
  const [employerToEdit, setEmployerToEdit] = useState<Employer | null>(null);
  const [showWc1ReportOptions, setShowWc1ReportOptions] =
    useState<boolean>(false);
  const [showWc1EDit, setShowWc1Edit] = useState<boolean>(false);
  const [wc1, setWc1] = useState<Wc1 | null>(null);
  const [attach, setAttach] = useState<boolean>(false);

  const [showPharmacyCards, setShowPharmacyCards] = useState<boolean>(false);
  const [showSecureShare, setShowSecureShare] = useState<boolean>(false);
  const [currentEmployer, setcurrentEmployer] = useState<Employer | null>(null);

  const {
    showBlankModal,
    blankModalMessage,
    setShowBlankModal,
    setBlankModalMessage,
  } = useBlankModal();

  const {
    claim,
    claimant,
    ediTransactions,
    employer,
    insuranceCompany,
    hasOpenNcm,
    isUserPriorityClaim,
    claimTypeConfiguration,
    additionalNotes,
  } = useAppSelector((state) => state.currentClaimReducer);
  const { policies } = useAppSelector((state) => state.employer);
  const { addresses } = useAppSelector((state) => state.address);

  useEffect(() => {
    getClaimantInfo();
    getEmployerInfo();
    getWc1();
  }, [claim, claimant, employer, policies, addresses]);

  useEffect(() => {
    if (currentEmployer && currentEmployer !== employer) {
      handleUWNotes(currentEmployer.employerId, 0);
    }
    setcurrentEmployer(employer);
  }, [employer]);

  const handleUWNotes = async (employerId: number, policyId: number) => {
    await ClaimApi.getEmployerPolicyUWNotes(employerId, policyId)
      .then((res) => {
        if (res.data && res.data[0] && res.data[0].length > 0) {
          setBlankModalMessage(
            <div>
              {res.data != null && res.data.length > 0 && (
                <div>
                  <b>UW Notes:</b>
                  <br />
                  <ul>
                    {res.data.map((note) => (
                      <li key={note}>{note}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          );
          setShowBlankModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClaimantInfo = () => {
    if (!claimant) {
      setClaimantInfo('');
      return;
    }

    const doi = displayDateOnly(claim?.injuryDate ?? claim?.incidentDate ?? '');
    const doh = displayDateOnly(claimant?.employmentDate ?? '');
    const dob = displayDateOnly(claimant?.dob ?? '');
    const currentAge = calculateAge(claimant?.dob ?? '');
    const ageAtInjury = calculateAgeAtInjury(
      claim?.injuryDate ?? claim?.incidentDate ?? '',
      claimant?.dob ?? ''
    );
    const address = claimant?.usePaymentAddress
      ? claimant.paymentAddress
      : claimant?.mainAddress;

    const info = `Claimant: ${claimant?.firstName} ${claimant?.lastName}
SSN: ${formatSSN(claimant?.ssn ?? '')}   Sex: ${claimant?.sex ?? 'N/A'}
DOI: ${doi ?? ''}  DOH: ${doh ?? ''}
DOB: ${dob ?? ''}  Current Age: ${currentAge ?? ''}   
Age AT DOI: ${ageAtInjury ?? ''}
${address?.address1 ?? ''}
${address?.address2 ?? ''}${address?.city ?? ''}, ${address?.state ?? ''} ${
      address?.zip ?? ''
    }
    `;
    setClaimantInfo(info);
  };

  const getEmployerInfo = () => {
    if (!employer) {
      setEmployerInfo('');
      return;
    }
    const policy = policies.find((p) => claim?.policyId === p.policyId);
    const startDate = displayDateOnly(policy?.startDate ?? '');
    const endDate = displayDateOnly(policy?.endDate ?? '');
    let info = `Employer:  ${employer?.name}`;
    if (claim?.policyId) {
      info =
        info +
        `
Policy Number: ${policy?.policyNumber ?? ''}  
Policy Date: ${startDate} to ${endDate} 
Location Name:  ${employer?.name}
${employer?.address ?? ''}
${employer?.address2 ?? ''}${employer?.city ?? ''}, ${employer?.state ?? ''} ${
          employer?.zip ?? ''
        }`;
    }
    setEmployerInfo(info);
  };

  const getEdiStatusDescription = () => {
    if (ediTransactions?.length) {
      const transactions = [...ediTransactions];
      const sorted = transactions.sort((a, b) => {
        const aDate = new Date(a.submitDate);
        const bDate = new Date(b.submitDate);
        return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
      });

      return sorted[0].ediStatus?.ediStatusDesc;
    }

    return '';
  };

  const onHandleIsUserPriorityClaim = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateUserPriorityClaim({
        claimNumber: claim?.claimNo!,
        active: !isUserPriorityClaim,
      })
    );
  };

  const handleAddEditEmployer = (employer: Employer | null) => {
    setEmployerToEdit(employer);
    setShowEmployerSelect(false);
    setShowEmployerAddEdit(true);
  };

  const getWc1 = () => {
    if (claim?.claimNo) {
      Wc1Api.getWc1ForClaim(claim.claimNo)
        .then((res) => {
          setWc1(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleViewWc1 = (fileDownload: FileDownload) => {
    setFileDownload(fileDownload);
    setShowWc1ReportOptions(false);
    setShowDocViewModal(true);
  };

  const handleViewEmployerPanel = () => {
    if (claim?.employerPanelId) {
      viewEmployerPolicyPanel(claim?.claimNo, claim?.employerPanelId);
    }
  };

  const viewEmployerPolicyPanel = (claimNumber: string, id: number) => {
    WcPanelApi.viewEmployerPolicyPanelById(claimNumber, id)
      .then((res) => {
        setFileDownload(res.data);
        setShowDocViewModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTryAdd = () => {
    if (claim?.claimNo) {
      setRefreshing(true);
      WcPanelApi.tryAddEmployerPolicyPanel(claim?.claimNo)
        .then((res) => {
          if (res.data.success) {
            dispatch(fetchClaimByClaimNumber(claim?.claimNo));
            viewEmployerPolicyPanel(
              claim?.claimNo,
              +res.data.affectedEntityIdentifier
            );
          } else {
            toast.error(
              res.data.message ?? 'Failed to get EmployerPolicyPanel'
            );
          }
          setRefreshing(false);
        })
        .catch((err) => {
          setRefreshing(false);
          console.log(err);
          toast.error('Failed to get EmployerPolicyPanel');
        });
    }
  };

  const handleMedicalStatuteOfLimitations400Week = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (claim) {
      const updatedClaim: Claim = {
        ...claim,
        medicalStatuteOfLimitations400Week:
          !claim?.medicalStatuteOfLimitations400Week,
      };

      ClaimApi.updateClaim(updatedClaim)
        .then((res) => {
          if (res.data.success) {
            dispatch(fetchClaimByClaimNumber(claim.claimNo));
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update claim');
        });
    }
  };

  const handleSpecialInstructions = () => {
    setBlankModalMessage(
      <div>
        {employer != null &&
          employer.notes != null &&
          employer.notes.length > 0 && (
            <div>
              <b>Employer Notes:</b>
              <br />
              <ul>
                <li>{employer.notes}</li>
              </ul>
            </div>
          )}
        {insuranceCompany != null &&
          insuranceCompany.notes != null &&
          insuranceCompany.notes.length > 0 && (
            <div>
              <b>Insurance Company Notes:</b>
              <br />
              <ul>
                <li>{insuranceCompany.notes}</li>
              </ul>
            </div>
          )}
        {additionalNotes != null && additionalNotes.length > 0 && (
          <div>
            <b>Additional Notes:</b>
            <br />
            <ul>
              {additionalNotes.map((note) => (
                <li>{note}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
    setShowBlankModal(true);
  };

  return (
    <div className='position-relative'>
      <div className='w-100'>
        <div className={`${styles.firstRow}`}>
          <div className='d-flex'>
            {claimNumber && <ClaimActionButton />}
            {claimNumber && (
              <ClaimReportButton
                setAttach={setAttach}
                setShowWc1ReportOptions={setShowWc1ReportOptions}
              />
            )}
          </div>
          {claimTypeConfiguration?.find((f) => f.fieldName === 'ediStatusLabel')
            ?.visible && (
            <div>
              <span className='fw-bold align-self-center'>
                Current EDI Status: {getEdiStatusDescription()}
              </span>
              <span className='fw-bold align-self-center'></span>
            </div>
          )}
          <span>
            Claim Number: {claimNumber ?? ''} ({ClaimTypesString[claimType]})
          </span>
        </div>
      </div>
      <div
        className={`w-100  d-flex justify-content-between ${styles.claimDetailTopSecondRowHeight}`}
      >
        <div
          className={`${styles.claimantEmployerDetails} ${styles.claimDetailTopSecondRowHeight}`}
        >
          <textarea
            className={`form-control ${styles.readonlyTextarea}`}
            placeholder='Click to Select Claimant'
            id='claimant-info'
            name='claimant-info'
            onClick={() => setShowClaimantForm(true)}
            defaultValue={claimantInfo}
            readOnly
          ></textarea>
          <textarea
            className={`form-control ${styles.readonlyTextarea}`}
            placeholder='Click to Select Employer'
            id='employer-info'
            name='employer-info'
            onClick={() => setShowEmployerSelect(true)}
            defaultValue={employerInfo}
            readOnly
          ></textarea>
        </div>
        {claimNumber && (
          <div
            className={`${styles.rightSide} ${styles.claimDetailTopSecondRowHeight}`}
          >
            <div className={`${styles.rightSideFirst}`}>
              <div className={`${styles.rightSideFirstTop}`}>
                {claimTypeConfiguration?.find(
                  (f) => f.fieldName === 'viewWC1Button'
                )?.visible && (
                  <Button
                    type='button'
                    onClick={() => {
                      setAttach(false);
                      setShowWc1ReportOptions(true);
                    }}
                  >
                    <div className='button-icon-text'>
                      <FaSearch />
                      View Wc1
                    </div>
                  </Button>
                )}

                {claimTypeConfiguration?.find(
                  (f) => f.fieldName === 'viewPanel'
                )?.visible && (
                  <ButtonGroup>
                    <Button
                      type='button'
                      onClick={() => {
                        handleViewEmployerPanel();
                      }}
                      disabled={claim?.employerPanelId === null}
                    >
                      <div className='button-icon-text'>
                        <FaFileAlt />
                        View Panel
                      </div>
                    </Button>
                    <Button
                      type='button'
                      className='ms-1'
                      onClick={() => {
                        handleTryAdd();
                      }}
                    >
                      <div className='button-icon-text'>
                        {refreshing ? (
                          <Spinner size='sm' animation='grow' />
                        ) : (
                          <FaSync />
                        )}
                      </div>
                    </Button>
                  </ButtonGroup>
                )}
                {claimTypeConfiguration?.find(
                  (f) => f.fieldName === 'pharmacyCards'
                )?.visible && (
                  <Button
                    type='button'
                    className='ms-1'
                    onClick={() => setShowPharmacyCards(true)}
                  >
                    <div className='button-icon-text'>
                      <FaIdCard />
                      Pharmacy Cards
                    </div>
                  </Button>
                )}
              </div>

              <div className={`${styles.rightSideFirstTop}`}>
                {claimTypeConfiguration?.find(
                  (f) => f.fieldName === 'editWC1Button'
                )?.visible && (
                  <Button
                    type='button'
                    disabled={wc1 === null}
                    onClick={() => setShowWc1Edit(true)}
                  >
                    <div className='button-icon-text'>
                      <FaEdit />
                      Edit Wc1
                    </div>
                  </Button>
                )}
                <Button
                  type='button'
                  variant='danger'
                  disabled={
                    !employer?.notes &&
                    !insuranceCompany?.notes &&
                    additionalNotes?.length == 0
                  }
                  onClick={handleSpecialInstructions}
                >
                  <div className='button-icon-text'>
                    <FaClipboard />
                    Special Instructions
                  </div>
                </Button>
                <div style={{ width: '160px' }}>
                  {claimant?.cellPhone && (
                    <Button
                      type='button'
                      onClick={() => {
                        document.body.click();
                        window.open(
                          `/text-conversation/claimant/${claimant?.claimantId}/insurco/${insuranceCompany?.insurcoid}/scandocId/0`,
                          `newwindow${claimant?.claimantId}`,
                          'width=450,height=560'
                        );
                      }}
                    >
                      <div className='button-icon-text'>
                        <MdMessage />
                        Text Message
                      </div>
                    </Button>
                  )}
                </div>
              </div>
              <div className={`${styles.rightSideFirstBottom}`}>
                {claimTypeConfiguration?.find((f) => f.fieldName === 'attachWC')
                  ?.visible && (
                  <Button
                    type='button'
                    onClick={() => {
                      setAttach(true);
                      setShowWc1ReportOptions(true);
                    }}
                  >
                    <div className='button-icon-text'>
                      <FaCheck />
                      Attach Wc1
                    </div>
                  </Button>
                )}
                {claimTypeConfiguration?.find(
                  (f) => f.fieldName === 'medicalStatuteOfLimitations400Week'
                )?.visible && (
                  <Form.Check
                    type='checkbox'
                    checked={!!claim?.medicalStatuteOfLimitations400Week}
                    onChange={handleMedicalStatuteOfLimitations400Week}
                    id='fullClaim.claim.medicalStatuteOfLimitations400Week'
                    label='400 week med statute of limitations has run'
                    readOnly
                  />
                )}
              </div>
            </div>
            <div className={`${styles.rightSideSecond}`}>
              <Form.Check
                type='switch'
                id='isUserPriorityClaim'
                name='fullClaim.claim.isUserPriorityClaim'
                onChange={onHandleIsUserPriorityClaim}
                checked={!!isUserPriorityClaim}
                label={`Priority Claim ${isUserPriorityClaim ? 'on' : 'off'}`}
              />
              {claimTypeConfiguration?.find((f) => f.fieldName === 'hasOpenNcm')
                ?.visible && (
                <Form.Check
                  type='switch'
                  id='hasOpenNcm'
                  name='hasOpenNcm'
                  checked={!!hasOpenNcm}
                  label={`NCM ${hasOpenNcm ? 'on' : 'off'}`}
                  readOnly
                />
              )}
              <Button
                type='button'
                onClick={() => {
                  setShowSecureShare(true);
                }}
              >
                <div className='button-icon-text'>
                  <FaFolderOpen />
                  Shared Files
                </div>
              </Button>
            </div>
          </div>
        )}
      </div>

      <ClaimantForm
        show={showClaimantForm}
        setShow={setShowClaimantForm}
        claimant={claimant ?? undefined}
      />
      <div>
        <EmployerSelect
          show={showEmployerSelect}
          setShow={setShowEmployerSelect}
          setEmployerToEdit={handleAddEditEmployer}
          employer={employer ?? undefined}
        />
        <EmployerAddEdit
          show={showEmployerAddEdit}
          employer={employerToEdit}
          setShow={setShowEmployerAddEdit}
          setEmployerToEdit={handleAddEditEmployer}
        />
      </div>

      <Wc1ReportOptions
        show={showWc1ReportOptions}
        setShow={setShowWc1ReportOptions}
        claimNumber={claim?.claimNo ?? ''}
        wc1={wc1}
        attach={attach}
        handleViewWc1={handleViewWc1}
      />
      <EditWc1
        show={showWc1EDit}
        setShow={setShowWc1Edit}
        wc1={wc1}
        getWc1={getWc1}
      />
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <BlankModal
        show={showBlankModal}
        setShow={setShowBlankModal}
        message={blankModalMessage}
      />
      <PharmacyCardsModal
        show={showPharmacyCards}
        setShow={setShowPharmacyCards}
        claimNumber={claim?.claimNo ?? ''}
      />
      <SharedFilesModal
        setShow={setShowSecureShare}
        show={showSecureShare}
        claimNumber={claim?.claimNo}
      />
    </div>
  );
}
