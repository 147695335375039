import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { displayDateOnly } from '../../Utils';
import { ExcessDetailObj } from '../../ApiTypes/ExcessDetailObj';
import accounting from 'accounting';

export default function ExcessDetailTable({
  data,
}: {
  data: ExcessDetailObj[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ExcessDetailObj>[] = [
    {
      header: 'Company Name',
      accessorKey: 'companyName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Insurance Company',
      accessorKey: 'insuranceCo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Policy Number',
      accessorKey: 'policyNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Start',
      accessorKey: 'datePolicyPeriodStart',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.datePolicyPeriodStart ?? ''),
    },
    {
      header: 'End',
      accessorKey: 'datePolicyPeriodEnd',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.datePolicyPeriodEnd ?? ''),
    },

    {
      header: 'SIR',
      accessorFn: (d) => `${d.sir}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.sir ?? 0),
    },
    {
      header: 'Treaty Year',
      accessorFn: (d) => `${d.treatyYear}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
