import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Container,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FormApi } from 'final-form';
import { add, format, isAfter, isBefore, isWeekend } from 'date-fns';
import { FaChevronLeft, FaChevronRight, FaEdit } from 'react-icons/fa';
import { ClaimNote } from '../../ApiTypes/ClaimNote';
import {
  createDateObject,
  displayDateOnly,
  parseDatesForServer,
} from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

import styles from './index.module.css';
import { eachWeekendOfInterval } from 'date-fns/esm';
import { useAppSelector } from '../../Reducers/Store';
import { ClaimNoteType } from '../../ApiTypes/ClaimNoteType';
import ClaimNotesApi from '../../Api/ClaimNotesApi';
import { toast } from 'react-toastify';
import { requiredField } from '../../Utils/FieldValidation';
import { ClaimTypes } from '../../ApiTypes/ClaimTypeConfiguration';
import ReactQuill from 'react-quill';

export default function EditCreateNote({
  show,
  setShow,
  selectedNote,
  getClaimNotes,
  claimNumber,
  noteType,
  newer,
  older,
  afterSubmit,
  claimType,
  tabFrom,
}: {
  show: boolean;
  setShow: () => void;
  selectedNote: ClaimNote | null;
  getClaimNotes: () => void;
  claimNumber: string;
  noteType?: number;
  newer?: (current: ClaimNote) => void;
  older?: (current: ClaimNote) => void;
  afterSubmit?: (values: ClaimNote) => void;
  claimType: number;
  tabFrom: string;
}) {
  const [presetDateValue, setPresetDateValue] = useState<string>('');
  const [isEditable, setIsEditable] = useState<boolean>(true);
  const [canBeCompleted, setCanBeCompleted] = useState<boolean>(true);
  const [quillValue, setQuillValue] = useState('');

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      [{ color: ['red', '#785412'] }],
      [{ background: ['yellow', 'red', '#785412'] }],
      ['clean'],
    ],
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'align',
  ];

  const { adjusters, claimNoteTypes, nurseCaseManagers, claimsAssistants } =
    useAppSelector((state) => state.reference);

  const { userName, userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    handlePresetDateChange();
  }, [presetDateValue]);
  useEffect(() => {
    getIsEditable();
  }, [selectedNote, userName, userModel]);
  useEffect(() => {
    setQuillValue(
      selectedNote
        ? selectedNote.noteText.replace(/(?:\r\n|\r|\n)/g, '<br>')
        : ''
    );
  }, [selectedNote]);
  const getIsEditable = () => {
    if (selectedNote === null) {
      setIsEditable(true);
      setCanBeCompleted(true);
      return;
    }
    if (selectedNote.noteDate === null || selectedNote.noteDate === '') {
      setIsEditable(false);
    }
    const today = displayDateOnly(new Date());
    if (displayDateOnly(selectedNote.noteDate ?? '') === today) {
      if (
        selectedNote.userId.toLowerCase() !== userName.toLowerCase() &&
        !userModel?.user?.isAdmin
      ) {
        setIsEditable(false);
      } else {
        setIsEditable(true);
      }
    } else {
      setIsEditable(false);
    }
    if (
      selectedNote.followUpDate !== null &&
      selectedNote.followUpDate !== '' &&
      selectedNote.followUpComplete !== true
    ) {
      setCanBeCompleted(true);
    } else {
      setCanBeCompleted(false);
    }
  };

  let formInstance: FormApi<ClaimNote, Partial<ClaimNote>>;

  const handleSetPresetDate = (value: string) => {
    setPresetDateValue(value);
  };

  const handlePresetDateChange = () => {
    const today = new Date();
    if (!presetDateValue) return;
    let endDate: Date = today;
    endDate = add(today, { days: +presetDateValue });
    if (endDate.getDay() === 0) {
      endDate = add(endDate, { days: +1 });
    } else if (endDate.getDay() === 6) {
      endDate = add(endDate, { days: -+1 });
    }
    formInstance.change('followUpDate', displayDateOnly(endDate));
  };

  const calcFollowUpDateExcludeWeekends = (start: Date, days: number) => {
    let endDate = add(start, { days: days });
    const weekendDays = eachWeekendOfInterval({
      start,
      end: endDate,
    });
    if (weekendDays.length > 0) {
      endDate = add(endDate, { days: weekendDays.length });
    }
    endDateNotWeekend(endDate);
  };

  const endDateNotWeekend = (date: Date) => {
    if (isWeekend(date)) {
      const endDate = add(date, { days: 1 });
      endDateNotWeekend(endDate);
    } else {
      formInstance.change('followUpDate', displayDateOnly(date));
    }
  };

  const clearPresets = () => {
    setPresetDateValue('');
    setQuillValue('');
    if (selectedNote?.followUpDate) {
      formInstance.change(
        'followUpDate',
        displayDateOnly(selectedNote.followUpDate)
      );
    } else {
      formInstance.change('followUpDate', '');
    }
  };

  const onSubmit = (values: ClaimNote) => {
    const v = { ...values };
    v.noteText = quillValue;
    if (v.followUpDate) {
      v.followUpDate = parseDatesForServer(v.followUpDate);
    }
    if (v.followUpComplete && selectedNote?.followUpComplete === false) {
      v.followUpCompleteDate = format(new Date(), 'yyyy-MM-dd');
      v.completedBy = userName;
    }
    if (v.noteId && v.noteId > 0) {
      return updateNote(v);
    }
    return createNote(v);
  };

  const runCleanUp = () => {
    setPresetDateValue('');
    setQuillValue('');
  };

  const createNote = (values: ClaimNote) => {
    return ClaimNotesApi.createClaimNote(values)
      .then((res) => {
        if (res.data.success) {
          getClaimNotes();
          runCleanUp();
          setShow();
          afterSubmit && afterSubmit(values);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error('Failed to create note');
      });
  };
  const updateNote = (values: ClaimNote) => {
    return ClaimNotesApi.updateClaimNote(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getClaimNotes();
          runCleanUp();
          setShow();
          afterSubmit && afterSubmit(values);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error('Failed to update note');
      });
  };

  const handleNoteTypeChange = (id: number) => {
    if (!id) return;
    if (selectedNote) return;
    const type = claimNoteTypes.find((x) => x.id === +id);
    if (type && type?.name.toLowerCase().includes('diary')) {
      setQuillValue(type.name);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        runCleanUp();
        setShow();
        setQuillValue('');
      }}
      dialogClassName=''
      aria-labelledby='Edit-Create-Note-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Edit-Create-Note-Form-modal'
        >
          <FaEdit className='pe-1' />
          {selectedNote ? 'Edit' : 'Create'}{' '}
          {tabFrom === 'Diary' ? 'Diary' : 'Note'}
          {!isEditable && canBeCompleted && (
            <div className='bg-info ms-3' style={{ borderRadius: '4px' }}>
              <p className='fs-5 px-3 m-0 text-dark fw-normal'>
                Note can only be completed
              </p>
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {newer && older && selectedNote && (
            <div className='d-flex justify-content-around py-2'>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                className='button-icon-text'
                onClick={() => newer(selectedNote)}
              >
                <FaChevronLeft /> Newer
              </Button>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                className='button-icon-text'
                onClick={() => older(selectedNote)}
              >
                Older <FaChevronRight />
              </Button>
            </div>
          )}
          <RFFForm
            onSubmit={onSubmit}
            initialValues={
              selectedNote
                ? {
                    ...selectedNote,
                    assignedTo:
                      selectedNote.assignedTo?.toLocaleLowerCase() ?? '',
                  }
                : {
                    claimNo: claimNumber,
                    noteId: 0,
                    userId: userName,
                    noteDate: format(new Date(), 'yyyy-MM-dd'),
                    noteType: noteType ?? undefined,
                    assignedTo: userName.toLowerCase(),
                  }
            }
            validate={(values) => {
              const errors: {
                [Property in keyof ClaimNote]?: string;
              } = {};
              if (values.followUp && !values.followUpDate) {
                errors.followUpDate = 'Required';
              }
              if (values.followUp && !values.assignedTo) {
                errors.assignedTo = 'Required';
              }
              if (values.followUpDate && !values.followUp) {
                errors.followUp = 'Required when setting a follow up date';
              }

              return errors;
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <div
                    className={`d-flex justify-content-between ${styles.address3} ${styles.address3children}`}
                  >
                    <Field
                      name='noteDate'
                      label='Note Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                      disabled={!isEditable}
                    />
                    <Field
                      name='userId'
                      type='text'
                      label='User'
                      component={FieldBSRenderText}
                      disabled={!isEditable}
                    />
                    <Field
                      name='noteType'
                      label='Note Type'
                      options={claimNoteTypes
                        .filter(
                          (c) =>
                            (c.claimTypeId === claimType ||
                              c.claimTypeId === null) &&
                            ((c.isDiary === true && tabFrom === 'Diary') ||
                              (c.isDiary === false && tabFrom === 'Notes') ||
                              c.isDiary === null)
                        )
                        .sort((a, b) => a.name!.localeCompare(b.name!))}
                      optionMethod={(options: ClaimNoteType[]) =>
                        options.map((o) => (
                          <option key={o.id} value={o.id}>
                            {o.name}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                      onChange={handleNoteTypeChange}
                    />
                  </div>
                  <div
                    className={`d-flex justify-content-between align-items-center ${styles.address3} ${styles.address3children}`}
                  >
                    <div
                      className={`${styles.width33} d-flex justify-content-start ${styles.flexGap1rem}`}
                    >
                      <Field
                        name='followUp'
                        type='checkbox'
                        label='Follow Up'
                        checked={!!values?.followUp}
                        component={FieldBSRenderCheckbox}
                        disabled={!isEditable}
                      />
                    </div>
                    <Field
                      name='followUpDate'
                      label='Follow Up Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                      disabled={!canBeCompleted}
                    />

                    <Field
                      name='assignedTo'
                      label='Assigned to'
                      options={[
                        ...adjusters
                          .filter((x) => x.active || x.active === null)
                          .map((x) => x.userid?.toLowerCase() ?? ''),
                        ...nurseCaseManagers
                          .filter((x) => x.active || x.active === null)
                          .map((x) => x.userId?.toLowerCase() ?? ''),
                        ...claimsAssistants
                          .filter((x) => x.active || x.active === null)
                          .map((x) => x.userId?.toLocaleLowerCase() ?? ''),
                      ].sort((a, b) => (a ?? '').localeCompare(b ?? ''))}
                      optionMethod={(options: string[]) =>
                        options.map((o) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <div
                    className={`d-flex justify-content-center ${
                      !isEditable && canBeCompleted ? 'bg-info' : ''
                    }`}
                  >
                    <Field
                      name='followUpComplete'
                      type='checkbox'
                      label='Completed'
                      checked={!!values?.followUpComplete}
                      component={FieldBSRenderCheckbox}
                      disabled={!canBeCompleted}
                    />
                  </div>
                  <div className='d-flex align-items-center mb-1'>
                    <p className='m-0 me-2'>Pre-Set Date Options</p>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={clearPresets}
                      disabled={!isEditable}
                    >
                      Clear
                    </Button>
                  </div>
                  <div className='d-flex justify-content-between mb-3'>
                    <Field
                      name='presetDate'
                      type='radio'
                      label='30'
                      value='30'
                      checked={presetDateValue === '30'}
                      onCheckChange={handleSetPresetDate}
                      component={FieldBSRenderCheckbox}
                      disabled={!isEditable}
                    />
                    <Field
                      name='presetDate'
                      type='radio'
                      label='45'
                      value='45'
                      checked={presetDateValue === '45'}
                      onCheckChange={handleSetPresetDate}
                      component={FieldBSRenderCheckbox}
                      disabled={!isEditable}
                    />
                    <Field
                      name='presetDate'
                      type='radio'
                      label='60'
                      value='60'
                      checked={presetDateValue === '60'}
                      onCheckChange={handleSetPresetDate}
                      component={FieldBSRenderCheckbox}
                      disabled={!isEditable}
                    />
                    <Field
                      name='presetDate'
                      type='radio'
                      label='90'
                      value='90'
                      checked={presetDateValue === '90'}
                      onCheckChange={handleSetPresetDate}
                      component={FieldBSRenderCheckbox}
                      disabled={!isEditable}
                    />
                    <Field
                      name='presetDate'
                      type='radio'
                      label='120'
                      value='120'
                      checked={presetDateValue === '120'}
                      onCheckChange={handleSetPresetDate}
                      component={FieldBSRenderCheckbox}
                      disabled={!isEditable}
                    />
                    <Field
                      name='presetDate'
                      type='radio'
                      label='13 weeks'
                      value='91'
                      checked={presetDateValue === '91'}
                      onCheckChange={handleSetPresetDate}
                      component={FieldBSRenderCheckbox}
                      disabled={!isEditable}
                    />
                  </div>
                  <div>
                    <div>Note Text</div>
                    <ReactQuill
                      theme='snow'
                      value={quillValue}
                      onChange={setQuillValue}
                      modules={modules}
                      formats={formats}
                      readOnly={!isEditable}
                      className={styles.noteContainer}
                    />
                  </div>
                  <div className={styles.claimantFormButtonDiv}>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        runCleanUp();
                        form.reset();
                        setShow();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
