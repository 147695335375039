import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExportApi from '../../../Api/ExportApi';
import ReservesApi from '../../../Api/ReservesApi';
import { ClaimPaymentTotals } from '../../../ApiTypes/ClaimPaymentTotals';
import { ClaimReserveBuckets } from '../../../ApiTypes/ClaimReserveBuckets';
import { ClaimReservesWorksheet } from '../../../ApiTypes/ClaimReservesWorksheet';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import { downloadExcel } from '../../../Utils';
import ReserveWorksheetModal from '../../ReserveWorksheet/ReserveWorksheetModal';
import ChangeReserveBucketModal from './ChangeReserveBucketModal';
import { reservesExportHandler } from './ReservesExportHandler';
import ReservesTable from './ReservesTable';
import {
  UpdateReservesFormType,
  UpdateReservesRequest,
  UpdateReservesType,
} from './UpdateReservesType';
import WorksheetTable from './WorksheetTable';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../../DxReportRequestModal/useDxReportRequestModal';
import { getDxReport } from '../../DocViewModal/useDocViewModal';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../../Actions/DocViewActions';
import { FileDownload } from '../../../Types/FileDownLoad';
import { PtdResponse } from '../../../ApiTypes/PtdResponse';
import styles from './index.module.css';
import { ClaimTabsEnum } from '../../../ApiTypes/ClaimTypeTab';
import ChangeReserveBucketModalCustom from './ChangeReserveBucketModalCustom';
import { ClaimReserveBucketsUpdate } from '../../../ApiTypes/ClaimReserveBucketsUpdate';
import { ClaimTypes } from '../../../ApiTypes/ClaimTypeConfiguration';
import { fetchClaimReserveApprovals } from '../../../Actions/ClaimActions';

export default function ReservesTab({ claimType }: { claimType: number }) {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  const [claimReserveBuckets, setClaimReserveBuckets] = useState<
    ClaimReserveBuckets[]
  >([]);
  const [claimPaymentTotals, setClaimPaymentTotals] =
    useState<ClaimPaymentTotals | null>(null);
  const [claimReservesWorksheets, setClaimReservesWorksheets] = useState<
    ClaimReservesWorksheet[]
  >([]);
  const [selectedWorksheet, setSelectedWorksheet] =
    useState<ClaimReservesWorksheet | null>(null);
  const [indemnityBucket, setIndemnityBucket] =
    useState<ClaimReserveBuckets | null>(null);
  const [medicalBucket, setMedicalBucket] =
    useState<ClaimReserveBuckets | null>(null);
  const [legalBucket, setLegalBucket] = useState<ClaimReserveBuckets | null>(
    null
  );
  const [expenseBucket, setExpenseBucket] =
    useState<ClaimReserveBuckets | null>(null);

  const [showReserveWorksheetModal, setShowReserveWorksheetModal] =
    useState<boolean>(false);

  const [showChangeReserve, setShowChangeReserve] = useState<boolean>(false);
  const [showChangeReserveCustom, setShowChangeReserveCustom] =
    useState<boolean>(false);

  const [ptdResponse, setPtdResponse] = useState<PtdResponse | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const {
    claim,
    claimant,
    employer,
    claimTypeConfiguration,
    reserveApprovals,
  } = useAppSelector((state) => state.currentClaimReducer);

  const { userName } = useAppSelector((state) => state.user);

  useEffect(() => {
    getReserveBucketsForClaim();
    getClaimPaymentTotals();
    getClaimReservesWorksheets();
    getPtd();
  }, [claimNumber]);

  const getPtd = () => {
    if (claimNumber) {
      ReservesApi.getPtdForClaim(claimNumber)
        .then((res) => {
          setPtdResponse(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getReserveBucketsForClaim = () => {
    if (claimNumber) {
      setLoading(true);
      ReservesApi.getClaimReserveBucketsForClaim(claimNumber)
        .then((res) => {
          setClaimReserveBuckets(res.data);
          const i = res.data.find((b) => b.description === 'Indemnity');
          const m = res.data.find((b) => b.description === 'Medical');
          const l = res.data.find((b) => b.description === 'Legal');
          const e = res.data.find((b) => b.description === 'Expense');

          setIndemnityBucket(i ?? null);
          setMedicalBucket(m ?? null);
          setLegalBucket(l ?? null);
          setExpenseBucket(e ?? null);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setClaimReserveBuckets([]);
    }
  };

  const getClaimPaymentTotals = () => {
    if (claimNumber) {
      ReservesApi.getClaimPaymentTotals(claimNumber)
        .then((res) => {
          setClaimPaymentTotals(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setClaimPaymentTotals(null);
    }
  };

  const getClaimReservesWorksheets = () => {
    if (claimNumber) {
      ReservesApi.getClaimReservesWorksheetsForClaim(claimNumber)
        .then((res) => {
          setClaimReservesWorksheets(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      dispatch(fetchClaimReserveApprovals(claimNumber));
    }
  };

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const handleViewWorksheet = (sheet: ClaimReservesWorksheet) => {
    setSelectedWorksheet(sheet);
    const request: DevExpressReportRequest = {
      ...requestDx,
      report: DxAvailableReports.rptReserveWorksheet,
      reserveWorksheetId: sheet.id,
      insurCoId: claim?.insurcoid ?? 1,
    };
    getDxReport(request, setFileDownload, setShowDx);
  };

  const exportToExcel = () => {
    const data = reservesExportHandler(claimReserveBuckets);
    data.fileName = `${claimNumber}-Transactions.xls`;

    return ExportApi.exportToExcel(data)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeChangeModal = () => {
    setShowChangeReserve(false);
  };
  const closeChangeModalCustom = () => {
    setShowChangeReserveCustom(false);
  };

  const updateReserves = (values: UpdateReservesFormType) => {
    const copy = { ...values };
    const { Medical, Indemnity, Expense, Legal, reason } = copy;

    const newReserves = updateReservesRows([
      Medical!,
      Indemnity!,
      Expense!,
      Legal!,
    ]);

    const request: UpdateReservesRequest = {
      reserveBuckets: newReserves,
      reason: reason ?? '',
    };

    return ReservesApi.updateReserves(claimNumber!, request)
      .then((res) => {
        if (res.data.success) {
          afterUpdate();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update reserves');
      });
  };

  const updateReservesNew = (
    reserves: ClaimReserveBuckets[],
    reason: string
  ) => {
    const request: ClaimReserveBucketsUpdate = {
      data: reserves,
      reason: reason,
    };
    return ReservesApi.updateReservesNew(claimNumber!, request)
      .then((res) => {
        if (res.data.success) {
          afterUpdate();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update reserves');
      });
  };

  const updateReservesRows = (reserves: UpdateReservesType[]) => {
    reserves.forEach((r) => {
      const paid = +r.paid;
      let totalIncurred = +r.totalIncurred;
      totalIncurred += +(r.change ?? 0);
      r.reserves = totalIncurred - paid;
      r.totalIncurred = totalIncurred;
      r.change = 0;
    });
    return reserves;
  };

  const afterUpdate = () => {
    closeChangeModal();
    closeChangeModalCustom();
    getReserveBucketsForClaim();
    getClaimPaymentTotals();
  };

  return (
    <div className='w-100 h-100'>
      <div className='w-100'>
        <h4 className='float-start'>Reserves</h4>
        <div className='d-flex justify-content-center'>
          <Button
            type='button'
            variant='primary'
            size='sm'
            onClick={() => {
              if (claimNumber && claim?.status?.toUpperCase() === 'C') {
                toast.warn(
                  'You cannot add/edit reserves on a Closed Claim. Please Re-Open this Claim, or Open a new Claim.'
                );
              } else {
                if (claimType === ClaimTypes.WorkersComp) {
                  setShowChangeReserve(true);
                } else {
                  setShowChangeReserveCustom(true);
                }
              }
            }}
            className='me-3'
          >
            + / -
          </Button>
          <Button
            type='button'
            variant='primary'
            size='sm'
            onClick={exportToExcel}
            className=''
          >
            Export to Excel
          </Button>
        </div>
      </div>
      <ReservesTable
        claimReserveBuckets={claimReserveBuckets}
        claimPaymentTotals={claimPaymentTotals}
        getReserveBucketsForClaim={getReserveBucketsForClaim}
        getClaimPaymentTotals={getClaimPaymentTotals}
        medicalStatuteOfLimitations400Week={
          claim?.medicalStatuteOfLimitations400Week ?? false
        }
        loading={loading}
      />
      {claimTypeConfiguration?.find(
        (f) =>
          f.claimTabId === ClaimTabsEnum.Reserves &&
          f.fieldName === 'WorksheetTable'
      )?.visible && (
        <div>
          <div className='w-100'>
            <h4 className='float-start'>Worksheets</h4>
            <Button
              type='button'
              variant='primary'
              size='sm'
              className='mx-auto d-block'
              onClick={() => {
                if (claimNumber && claim?.status?.toUpperCase() === 'C') {
                  toast.warn(
                    'You cannot add/edit reserves on a Closed Claim. Please Re-Open this Claim, or Open a new Claim.'
                  );
                } else {
                  setShowReserveWorksheetModal(true);
                }
              }}
            >
              New Worksheet
            </Button>
          </div>
          <WorksheetTable
            getReserveBucketsForClaim={getReserveBucketsForClaim}
            getClaimPaymentTotals={getClaimPaymentTotals}
            worksheets={claimReservesWorksheets}
            getClaimReservesWorksheets={getClaimReservesWorksheets}
            setShowWorksheetModal={setShowReserveWorksheetModal}
            setSelectedWorksheet={setSelectedWorksheet}
            selectedWorksheet={selectedWorksheet}
            handleViewWorksheet={handleViewWorksheet}
          />
        </div>
      )}
      <ChangeReserveBucketModalCustom
        show={showChangeReserveCustom}
        setShow={closeChangeModalCustom}
        claim={claim}
        userName={userName}
        reserveBuckets={claimReserveBuckets}
        updateReserves={updateReservesNew}
      />
      <ChangeReserveBucketModal
        show={showChangeReserve}
        setShow={closeChangeModal}
        claim={claim}
        userName={userName}
        reserveBuckets={claimReserveBuckets}
        updateReserves={updateReserves}
      />
      {showReserveWorksheetModal && (
        <ReserveWorksheetModal
          show={showReserveWorksheetModal}
          setShow={setShowReserveWorksheetModal}
          claim={claim}
          claimant={claimant}
          employer={employer}
          selectedWorksheet={selectedWorksheet}
          getClaimReservesWorksheets={getClaimReservesWorksheets}
          setSelectedWorksheet={setSelectedWorksheet}
          indemnityBucket={indemnityBucket}
          medicalBucket={medicalBucket}
          legalBucket={legalBucket}
          expenseBucket={expenseBucket}
          claimPaymentTotals={claimPaymentTotals}
          getReserveBucketsForClaim={getReserveBucketsForClaim}
          getClaimPaymentTotals={getClaimPaymentTotals}
          ptdResponse={ptdResponse}
          reserveApprovals={reserveApprovals}
        />
      )}
    </div>
  );
}
