import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ChangeClaimNumberRequest } from '../ApiTypes/ChangeClaimNumberRequest';
import { Claim } from '../ApiTypes/Claim';
import { ClaimSearchRequest } from '../ApiTypes/ClaimSearchRequest';
import { ClaimSearchResult } from '../ApiTypes/ClaimSearchResult';
import {
  CreateClaimRequest,
  CreateClaimResponse,
} from '../ApiTypes/CreateClaimRequestResponse';
import { ExcessStatusObj } from '../ApiTypes/ExcessStatusObj';
import { FullClaim } from '../ApiTypes/FullClaim';
import { LinkedClaim } from '../ApiTypes/LinkedClaim';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { Wc1 } from '../ApiTypes/Wc1';
import { FileDownload } from '../Types/FileDownLoad';
import { ReserveApproval } from '../ApiTypes/ReserveApproval';

class ClaimApi {
  static getClaimByClaimNumber(claimNo: string) {
    return axios.get<Claim>(
      `${API_URL}/api/claims/${claimNo}`,
      getAuthHeader()
    );
  }
  static getFullClaimByClaimNumber(claimNo: string) {
    return axios.get<FullClaim>(
      `${API_URL}/api/claims/${claimNo}/full`,
      getAuthHeader()
    );
  }
  static searchClaims(search: ClaimSearchRequest) {
    return axios.post<ClaimSearchResult[]>(
      `${API_URL}/api/claims/find`,
      search,
      getAuthHeader()
    );
  }
  static updateClaim(claim: Claim) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claim.claimNo}`,
      claim,
      getAuthHeader()
    );
  }
  static createSkeletonClaim(request: CreateClaimRequest) {
    return axios.post<CreateClaimResponse>(
      `${API_URL}/api/claims`,
      request,
      getAuthHeader()
    );
  }
  static getWc1(claimNumber: string) {
    return axios.get<Wc1>(
      `${API_URL}/api/claims/${claimNumber}/wc1`,
      getAuthHeader()
    );
  }
  static deleteClaim(claimNumber: string) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}`,
      getAuthHeader()
    );
  }
  static getLinkedClaims(claimNumber: string) {
    return axios.get<LinkedClaim[]>(
      `${API_URL}/api/claims/${claimNumber}/linked`,
      getAuthHeader()
    );
  }
  static linkClaims(claimNumber: string, links: LinkedClaim[]) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/linked`,
      links,
      getAuthHeader()
    );
  }
  static printClaimLabel(claimNumber: string) {
    return axios.get<FileDownload>(
      `${API_URL}/api/claims/${claimNumber}/label`,
      getAuthHeader()
    );
  }
  static sendnewclaimemail(claimNumber: string) {
    return axios.get<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/sendnewclaimemail`,
      getAuthHeader()
    );
  }
  static changeClaimNumber(request: ChangeClaimNumberRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${request.oldClaimNumber}/change`,
      request,
      getAuthHeader()
    );
  }
  static getExcessStatus() {
    return axios.get<ExcessStatusObj[]>(
      `${API_URL}/api/claims/excessstatus`,
      getAuthHeader()
    );
  }
  static getClaimsActivitySummary(insurCoId: number, year: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/claims/activitysummary?insurcoid=${insurCoId}&year=${year}`,
      getAuthHeader()
    );
  }
  static getOpenClaimNumbers() {
    return axios.get<string[]>(
      `${API_URL}/api/claims/openorrecentclaimnumbers`,
      getAuthHeader()
    );
  }
  static getOpenLastNames() {
    return axios.get<string[]>(
      `${API_URL}/api/claims/getopenorrecentclaimantlastnames`,
      getAuthHeader()
    );
  }
  static getOpenDobs() {
    return axios.get<string[]>(
      `${API_URL}/api/claims/getopenorrecentclaimantdob`,
      getAuthHeader()
    );
  }
  static completeDiaries(claimNumber: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/completediaries/${claimNumber}`,
      null,
      getAuthHeader()
    );
  }
  static getEmployerPolicyUWNotes(employerId: number, policyId: number) {
    return axios.get<string[]>(
      `${API_URL}/api/claims/employers/${employerId}/policy/${policyId}/notes/uw`,
      getAuthHeader()
    );
  }
  static getClaimReserveApprovals(claimNo: string) {
    return axios.get<ReserveApproval[]>(
      `${API_URL}/api/claims/${claimNo}/reserveapprovals`,
      getAuthHeader()
    );
  }
}
export default ClaimApi;
