import React, { ReactNode } from 'react';

const Basic = React.lazy(() => import('./Basic'));
const Superhero = React.lazy(() => import('./Superhero'));
type Props = {
  themeTypeId: number;
  children?: React.ReactNode;
};

const ThemeSelector: React.FC<Props> = ({ themeTypeId, children }) => (
  <>
    <React.Suspense fallback={null}>
      {themeTypeId === 1 && <Basic />}
      {themeTypeId === 2 && <Superhero />}
    </React.Suspense>
    {children}
  </>
);

export default ThemeSelector;
