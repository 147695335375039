import React from 'react';
import styles from './index.module.css';
import Select from 'react-select';

const redBorder = {
  borderColor: 'red',
};

type FinalRenderSelectProps = {
  input: any;
  label?: string;
  options: any[];
  optionMethod?: (options: any[]) => any[];
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  // ref,
  onChange: (value: any) => void;
  meta: { touched: boolean; error: string; warning: string };
};

type Option = {
  label: string;
  value: string;
};

export default function FieldBSRenderSelectHorizontalSearchable({
  input,
  label,
  options,
  optionMethod,
  required,
  autoFocus,
  placeholder,
  name,
  disabled,
  // ref,
  onChange,
  meta: { touched, error, warning },
}: FinalRenderSelectProps) {
  return (
    <div className={`${styles.finalBsRenderGrid} ${styles.smallFont} mb-2`}>
      <label
        htmlFor={input.name}
        className='form-label fs-6 align-self-start m-0'
      >
        {label}
      </label>
      <div>
        <Select
          {...input}
          id={input.name}
          disabled={disabled}
          onChange={(e: any) => {
            input.onChange(e ? e.value : null);
            onChange && onChange(e ? e.value : null);
          }}
          style={touched && error ? redBorder : {}}
          isSearchable
          isClearable
          options={optionMethod && optionMethod(options)}
          value={
            options && optionMethod && input.value
              ? optionMethod(options).find(
                  (option: Option) => option.value === input.value
                )
              : ''
          }
          autoComplete='off'
        />
        {touched &&
          ((error && (
            <span className={`${styles.fieldError} text-danger`}>{error}</span>
          )) ||
            (warning && (
              <span className={`${styles.fieldWarning} text-warning`}>
                {warning}
              </span>
            )))}
      </div>
    </div>
  );
}
