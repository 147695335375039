import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ClaimNoteType } from '../../ApiTypes/ClaimNoteType';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

export default function ClaimNoteTypeTable({
  data,
  handleDelete,
}: {
  data: ClaimNoteType[];
  handleDelete: (t: ClaimNoteType) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ClaimNoteType>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <Button
            type='button'
            variant='danger'
            size='sm'
            onClick={() => {
              handleDelete(row.original);
            }}
          >
            <FaTrash />
          </Button>
        );
      },
    },
    {
      header: 'Id',
      accessorFn: (d) => `${d.id}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      size: 100,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
