import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaShare } from 'react-icons/fa';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import { UserEmailList } from '../../../ApiTypes/UserEmailList';
import { useAppSelector } from '../../../Reducers/Store';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import styles from './index.module.css';
import CreatableSelect from 'react-select/creatable';
import { EmailDocumentsRequest } from '../../../ApiTypes/EmailDocumentsRequest';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';
import { ScanDocsPending } from '../../../ApiTypes/ScanDocsPending';
import UserEmailListApi from '../../../Api/UserEmailListApi';
import { StopPaymentRequest } from '../../../ApiTypes/StopPaymentRequest';
import { VPaymentTab } from '../../../ApiTypes/VPaymentTab';
import { format } from 'date-fns';
import PaymentsApi from '../../../Api/PaymentsApi';
import { CheckTransactionStatus } from '../../../ApiTypes/CheckTransactionStatus';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import { ClaimNoteType } from '../../../ApiTypes/ClaimNoteType';
import {
  ClaimCustomOption,
  OptionTypeEnum,
} from '../../../ApiTypes/ClaimCustomOption';

export default function StopPaymentModal({
  show,
  setShow,
  selectedPayment,
  setSelectedPayment,
  userEmailList,
  toEmailAddresses = [],
  stopPayment,
  paymentActionType,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedPayment: VPaymentTab | null;
  setSelectedPayment: (payment: VPaymentTab | null) => void;
  userEmailList: UserEmailList[];
  toEmailAddresses?: string[];
  stopPayment: (values: StopPaymentRequest) => void;
  paymentActionType: string;
}) {
  const { claimCustomOptions } = useAppSelector((state) => state.reference);
  const [transaction, setTransaction] = useState<CheckTransactionStatus>();
  const [messageEnabled, setMessageEnabled] = useState<boolean>(false);
  const [messageExplaination, setMessageExplaination] = useState<string>('');
  let formValues: StopPaymentRequest;

  useEffect(() => {
    setMessageEnabled(false);
    setMessageExplaination('');
    getCheckTransactionStatus();
  }, [selectedPayment]);

  const getCheckTransactionStatus = () => {
    if (selectedPayment?.txId) {
      PaymentsApi.getCheckTransactionStatus(selectedPayment?.txId)
        .then((res) => {
          setTransaction(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const emailList = useMemo(() => {
    const tos = toEmailAddresses.map((t) => ({
      label: t,
      value: t,
    }));

    const list = userEmailList.map((e) => ({
      label: e.emailAddress ?? '',
      value: e.emailAddress ?? '',
    }));

    return [...tos, ...list];
  }, [toEmailAddresses, userEmailList]);

  const { userModel } = useAppSelector((state) => state.user);
  const { employer, insuranceCompany } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const emailbody = (explanation: string) => {
    return (
      (paymentActionType === 'stop'
        ? 'Please check if this payment has cleared, if not, please stop payment. If it has cleared, please provide front and back copy.\n\n'
        : 'Please provide front and back of cancelled check or notify if not cleared.\n\n') +
      `Account Name: ${insuranceCompany?.shortname}\n` +
      `Check Number: ${transaction?.checkNumber ?? '(N/A)'}\n` +
      `Check Date: ${
        transaction?.checkDate
          ? format(new Date(transaction?.checkDate), 'MM-dd-yyy')
          : '(N/A)'
      }\n` +
      `Payee: ${transaction?.checkPayableTo}\n` +
      `Transaction Amount: $${transaction?.amount}\n` +
      `Check Amount: $${transaction?.bulkAmount}\n` +
      `Transactions on Check: ${transaction?.transactionCount}\n\n` +
      `Explanation: ${explanation}`
    );
  };

  const onSubmit = (values: StopPaymentRequest) => {
    return stopPayment(values);
  };

  const handleExplanationChange = (id: number) => {
    if (!id) return;
    const test = claimCustomOptions.find((x) => x.id === +id);
    if (test?.name === 'Other-Please explain') {
      setMessageEnabled(true);
      setMessageExplaination(emailbody(''));
    } else {
      setMessageEnabled(false);
      setMessageExplaination(emailbody(test?.name ?? ''));
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setMessageExplaination('');
        setShow(false);
      }}
      aria-labelledby='Email-Documents-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Email-Documents-Form-modal'
        >
          <FaShare className='pe-1' />{' '}
          {paymentActionType === 'stop'
            ? 'Stop Payment Request'
            : 'Check Status Request'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            validate={(values: StopPaymentRequest) => {
              const errors: {
                [Property in keyof StopPaymentRequest]?: string;
              } = {};

              if (values?.emailAddresses.length === 0) {
                errors.emailAddresses = 'Required';
              }
              return errors;
            }}
            initialValues={{
              from: userModel?.user?.emailAddress ?? '',
              saveNote: true,
              subject:
                paymentActionType === 'stop'
                  ? 'Stop Payment Request'
                  : 'Check Status Request',
              // message: message,
              emailAddresses: toEmailAddresses,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formValues = values;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field name='emailAddresses'>
                    {({ input, meta: { touched, error } }) => (
                      <div
                        className={`position-relative ${styles.vFieldHeight}`}
                      >
                        <label
                          htmlFor={input.name}
                          className='form-label fs-6  m-0'
                        >
                          Email Addresses
                        </label>
                        <CreatableSelect
                          name={input.name}
                          placeholder='Send To'
                          isClearable
                          isMulti
                          defaultValue={emailList.slice(
                            0,
                            toEmailAddresses.length
                          )}
                          onChange={(e) => {
                            if (e) {
                              input.onChange(e.map((m) => m.value));
                            } else {
                              input.onChange(null);
                            }
                          }}
                          styles={{
                            control: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              borderColor: touched && error && 'red',
                            }),
                          }}
                          options={emailList}
                        />

                        {touched && error && (
                          <span className={`${styles.fieldError} text-danger`}>
                            {error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field
                    name='subject'
                    label='Subject'
                    type='text'
                    component={FieldBSRenderText}
                    // validate={requiredField}
                  />
                  <Field
                    name='explainationType'
                    label='Explanation'
                    options={claimCustomOptions
                      .filter(
                        (x) => x.type === OptionTypeEnum.CheckStatusExplanations
                      )
                      .sort((a, b) =>
                        (a.name ?? '').localeCompare(b.name ?? '')
                      )}
                    optionMethod={(options: ClaimCustomOption[]) =>
                      options.map((o) => (
                        <option key={o.id} value={o.id}>
                          {o.name}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                    onChange={handleExplanationChange}
                  />
                  <Field
                    name='message'
                    label='Message'
                    initialValue={messageExplaination}
                    rows={12}
                    component={FieldBSRenderTextArea}
                    disabled={!messageEnabled}
                    // validate={requiredField}
                  />
                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
