import React, { useState, useMemo } from 'react';
import { displayDateOnly } from '../../Utils';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';
import { FaCheckSquare } from 'react-icons/fa';

export default function ProviderLookUpTable({
  data,
  selectProvider,
  setProviders,
}: {
  data: ProviderSearchResult[];
  selectProvider: (provider: ProviderSearchResult) => void;
  setProviders: (providers: ProviderSearchResult[]) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const getAddress = (r: ProviderSearchResult) => {
    if (r.address2) {
      return `${r.address1}, ${r.address2}, ${r.city}, ${r.state} ${r.zip}`;
    }
    return `${r.address1}, ${r.city}, ${r.state} ${r.zip}`;
  };

  const columnData: ColumnDef<ProviderSearchResult>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 40,
      maxSize: 40,
      cell: (d) => {
        return (
          <Button
            type='button'
            size='sm'
            onClick={() => {
              selectProvider(d.row.original);
              setProviders([]);
            }}
          >
            Select
          </Button>
        );
      },
    },
    {
      header: 'Active',
      accessorFn: (d) => (d.active ? 'Yes' : 'No'),
      filterFn: 'includesStringSensitive',
      size: 35,
      cell: (d) => {
        if (d.row.original.active) {
          return <FaCheckSquare className='text-success' />;
        } else {
          return '';
        }
      },
    },
    {
      header: 'Provider Id',
      accessorKey: 'providerId',
      sortingFn: 'alphanumericCaseSensitive',
      size: 85,
      // filterFn: "weakEquals",
    },
    {
      header: 'Tax Id',
      accessorFn: (d) => d.taxId,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      size: 75,
    },
    {
      header: 'Payee Name',
      accessorFn: (d) => d.companyName,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Address',
      accessorFn: (d) => getAddress(d),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
      />
    </div>
  );
}
