import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ClaimStatisticsObj } from '../../ApiTypes/ClaimStatisticObj';
import { FaEye } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';

export default function ClaimSummaryTable({
  data,
}: {
  data: ClaimStatisticsObj[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ClaimStatisticsObj>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <Link to={`/adjuster-statistics/${row.original.userId}`}>
            <Button type='button' variant='outline-primary' size='sm'>
              View Stats
            </Button>
          </Link>
        );
      },
    },
    {
      header: 'User',
      accessorKey: 'userName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claims Created',
      accessorFn: (d) => `${d.claimsCreated}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claims Created (LT)',
      accessorFn: (d) => `${d.claimsCreatedLT}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claims Created (MO)',
      accessorFn: (d) => `${d.claimsCreatedMO}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Docs Added',
      accessorFn: (d) => `${d.documentsAdded}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Docs Added (LT)',
      accessorFn: (d) => `${d.documentsAddedLT}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Docs Added (MO)',
      accessorFn: (d) => `${d.documentsAddedMO}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Docs Moved',
      accessorFn: (d) => `${d.documentsMoved}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Docs Moved (LT)',
      accessorFn: (d) => `${d.documentsMovedLT}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Docs Moved (MO)',
      accessorFn: (d) => `${d.documentsMovedMO}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Percentage',
      accessorFn: (d) => d.percentDifferent.toFixed(2),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Current Doc Count',
      accessorFn: (d) => `${d.currentUnhandledDocsCount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Sent to Straight Pay',
      accessorFn: (d) => `${d.documentsSentToStraightPay}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Straight Pay Approved',
      accessorFn: (d) => `${d.documentsApprovedForStraightPay}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Sent to Comp IQ',
      accessorFn: (d) => `${d.documentsSentToCompIQ}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
