import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { FullClaim } from '../../ApiTypes/FullClaim';
import { FaEye } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';

export default function FroiClaimsTable({ claims }: { claims: FullClaim[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => claims, [claims]);

  const columnData: ColumnDef<FullClaim>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      cell: ({ row }) => {
        return (
          <Link to={`/claims/${row.original.claim?.claimNo}`}>
            <Button type='button' variant='danger' size='sm'>
              <FaEye />
            </Button>
          </Link>
        );
      },
    },
    {
      header: 'Claim No',
      accessorFn: (d) => d.claim?.claimNo,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'First Name',
      accessorFn: (d) => d.claimant?.firstName,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Last Name',
      accessorFn: (d) => d.claimant?.lastName,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date of Injury',
      accessorFn: (d) => d.claim?.injuryDate,
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.claim?.injuryDate ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
