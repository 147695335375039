import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Pbm } from '../../ApiTypes/Pbm';
import { FaEye } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function PbmTable({ data }: { data: Pbm[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<Pbm>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <Link to={`/pbms/${row.original.pbmid}`}>
            <Button type='button' variant='primary' size='sm' title='View/Edit'>
              <FaEye />
            </Button>
          </Link>
        );
      },
    },
    {
      header: 'Name',
      accessorKey: 'name',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Phone Number',
      accessorKey: 'phone',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Address',
      accessorKey: 'address',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'City',
      accessorKey: 'city',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'State',
      accessorKey: 'state',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Zip Code',
      accessorKey: 'zipCode',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
