import { combineReducers } from 'redux';
import AddressReducer from './AddressReducer';
import CurrentClaimReducer from './CurrentClaimReducer';
import EmployerReducer from './EmployerReducer';
import ReferenceReducer from './ReferenceReducer';
import UserReducer from './UserReducer';
import DocViewReducer from './DocViewReducer';
import ThemeReducer from './ThemeReducer';

const rootReducer = combineReducers({
  reference: ReferenceReducer,
  currentClaimReducer: CurrentClaimReducer,
  employer: EmployerReducer,
  address: AddressReducer,
  user: UserReducer,
  docView: DocViewReducer,
  theme: ThemeReducer,
});

export default rootReducer;
