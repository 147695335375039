import { createReducer } from '@reduxjs/toolkit';

import {
  clearState,
  setUserFromToken,
  fetchCurrentUser,
  setTheme,
} from '../Actions/index';
import { UserModel } from '../ApiTypes/UserModel';

type ThemeReducerType = {
  themeTypeId: number;
};

const initialState: ThemeReducerType = {
  themeTypeId: 0,
};

const ThemeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setTheme.fulfilled, (state, action) => {
      return { ...state, themeTypeId: action?.payload };
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    });
});

export default ThemeReducer;
