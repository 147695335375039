import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { PaymentApprovalGridModel } from '../../ApiTypes/PaymentApprovalGridModel';
import { useAppSelector } from '../../Reducers/Store';
import BSControlledSelect from '../Common/BSControlledSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import ApprovePaymentsTable from './ApprovePaymentsTable';
import styles from './index.module.css';

export default function ApprovePayments() {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const [selectedAccount, setSelectedAccount] = useState<number>(0);
  const [selectedInsuranceCompany, setSelectedInsuranceCompany] =
    useState<InsuranceCompany | null>(null);
  const [onlyMine, setOnlyMine] = useState<boolean>(true);
  const [fetching, setFetching] = useState(false);
  const [approvals, setApprovals] = useState<PaymentApprovalGridModel[]>([]);

  const handleAccountChange = (insurCoId: number | null) => {
    setSelectedAccount(insurCoId ?? 0);
    if (insurCoId && insurCoId > 0) {
      const company =
        insuranceCompanies.find((x) => x.insurcoid === insurCoId) ?? null;
      setSelectedInsuranceCompany(company);
    } else {
      setSelectedInsuranceCompany(null);
    }
  };

  useEffect(() => {
    getTransactions();
  }, [selectedAccount, onlyMine]);

  const getTransactions = () => {
    if (selectedAccount < 1) {
      // toast.info('Please select an account');
      return;
    }
    setFetching(true);
    PaymentsApi.getApprovals(selectedAccount, onlyMine)
      .then((res) => {
        setApprovals(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
        toast.error('Failed to get approvals');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Approve Payments</h1>
          </div>
        </div>
      </div>
      {/* <div className='w-100'> */}
      <div className='align-items-center position-relative pb-5'>
        <div className='position-absolute start-0'>
          <fieldset>
            <legend>Legend</legend>
            <div className='salmon text-dark'>
              Insufficient Reserves (Closed)
            </div>
            <div className='khaki text-dark'>Insufficient Reserves (Open)</div>
            <div className='orange text-dark'>Over $1000</div>
            <div className='fw-bold'>ACH Enabled</div>
          </fieldset>
        </div>
        <div className='d-flex justify-content-center align-items-center gap1Rem m-auto'>
          <BSControlledSelect
            name='account'
            label='Account'
            onChange={handleAccountChange}
            value={selectedAccount}
            options={insuranceCompanies}
            optionMethod={(options: InsuranceCompany[]) =>
              options.map((o) => (
                <option key={o.insurcoid} value={o.insurcoid}>
                  {`${o.accountnumber} - ${o.shortname}`}
                </option>
              ))
            }
          />
          <div className='form-check'>
            <label className='form-check-label'>
              <input
                id='onlyMyClaims'
                type='checkbox'
                className='form-check-input'
                checked={onlyMine}
                onChange={(e) => {
                  setOnlyMine(!onlyMine);
                }}
                style={styles}
              />
              Only my claims
            </label>
          </div>
          <Button
            type='button'
            variant='primary'
            size='sm'
            onClick={getTransactions}
          >
            {fetching ? (
              <Spinner
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Refresh'
            )}
          </Button>
        </div>
      </div>
      {/* </div> */}
      <ApprovePaymentsTable
        data={approvals}
        getTransactions={getTransactions}
        insuranceCompany={selectedInsuranceCompany}
      />
    </PageScaffold>
  );
}
