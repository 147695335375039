import React from 'react';
import stylesCss from './index.module.css';

type FinalRenderFieldProps = {
  input: any;
  label?: string;
  underInputNote?: string;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  value: any;
  name?: string;
  disabled?: boolean;
  className?: string;
  styles: any;
  checked?: boolean;
  defaultChecked?: boolean;
  checkboxStyle?: any;
  onCheckChange?: (value: any) => void;
  onBlur: (value: any) => void;
  meta: { touched: boolean; error: string; warning: string };
};

export default function FieldBSRenderCheckbox({
  input,
  label,
  checked,
  onCheckChange,
  styles,
  checkboxStyle,
  disabled,
  value,
  defaultChecked,
  meta: { touched, error, warning },
}: FinalRenderFieldProps) {
  return (
    <div className='form-check'>
      <label className='form-check-label'>
        <input
          {...input}
          className='form-check-input'
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={(e) => {
            input.onChange(e);
            const { value: eventValue, checked } = e.target;
            if (input.type === 'checkbox') {
              onCheckChange && onCheckChange(checked);
            } else {
              onCheckChange && onCheckChange(eventValue);
            }
          }}
          disabled={disabled ? disabled : false}
          style={styles}
          autoComplete='off'
        />
        {label}
      </label>
      {input.type === 'checkbox' &&
        touched &&
        ((error && (
          <div className={`${stylesCss.fieldError} text-danger`}>{error}</div>
        )) ||
          (warning && (
            <span className={`${stylesCss.fieldWarning} text-warning`}>
              {warning}
            </span>
          )))}
    </div>
  );
}
