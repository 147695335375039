import React, { useEffect } from 'react';
import decode from 'jwt-decode';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { DecodedToken } from '../../Types/DecodedToken';
import { fetchCurrentUser, setUserFromToken } from '../../Actions';
import { isAfter, toDate } from 'date-fns';
import { SiteFunctionsEnum } from '../../ApiTypes/SiteFunctions';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading';
import { ClaimTypesString } from '../../ApiTypes/ClaimTypeConfiguration';
import ThemeSelector from '../../Themes/ThemeSelector';

export default function RequireAuth({
  children,
  siteFunction,
  claimTypeAccess,
}: {
  siteFunction?: number;
  children: JSX.Element;
  claimTypeAccess?: number;
}) {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const { authenticated, userModel, fetchingUser } = useAppSelector(
    (state) => state.user
  );
  const { themeTypeId } = useAppSelector((state) => state.theme);

  useEffect(() => {
    hasSiteFunctionAvailable();
  }, [siteFunction, claimTypeAccess, fetchingUser]);

  const handleTokenAfterRefresh = async () => {
    const token = localStorage.getItem('gasToken');
    if (token) {
      const decoded: DecodedToken = decode(token);
      const now = new Date();
      dispatch(
        setUserFromToken({
          userName:
            decoded[
              'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
            ],
          exp: decoded.exp,
        })
      );
      const isValid = isAfter(toDate(decoded.exp * 1000), now);
      if (isValid) {
        await dispatch(fetchCurrentUser());
        return isValid;
      }
      return false;
    }
    return false;
  };

  const hasSiteFunctionAvailable = async () => {
    if ((siteFunction || claimTypeAccess) && !fetchingUser) {
      if (!userModel || !userModel.userSiteFunctions) {
        var valid = await handleTokenAfterRefresh();
        if (!valid) {
          navigate(-1);
          return;
        }
      }

      if (siteFunction) {
        const val = userModel?.userSiteFunctions?.some(
          (f) => f.siteFunctionId === siteFunction && f.enabled
        );
        if (!val) {
          toast.error(
            `User does not have permission to access "${SiteFunctionsEnum[siteFunction]}"`
          );
          navigate(-1);
        }
      }
      if (claimTypeAccess) {
        const val = userModel?.userClaimTypeAccesses?.some(
          (f) => f.claimTypeId === claimTypeAccess
        );
        if (!val) {
          toast.error(
            `User does not have permission to access "${ClaimTypesString[claimTypeAccess]}"`
          );
          navigate(-1);
        }
      }
    }
  };

  if (fetchingUser) {
    return <Loading />;
  }

  if (
    !localStorage.getItem('gasToken') ||
    (!authenticated && !handleTokenAfterRefresh() && !fetchingUser)
  ) {
    return <Navigate to='/login' replace />;
  }
  return (
    <ThemeSelector themeTypeId={userModel?.user?.themeId ?? 0}>
      <div>{children}</div>
    </ThemeSelector>
  );
}
