import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { NcmDashboardObj } from '../../ApiTypes/NcmDashboardObj';
import { Button } from 'react-bootstrap';
import { displayDateOnly } from '../../Utils';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function NcmDashboardTable({
  data,
  handleReassign,
  viewReport,
}: {
  data: NcmDashboardObj[];
  viewReport: (obj: NcmDashboardObj) => void;
  handleReassign: (cm: NcmDashboardObj) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<NcmDashboardObj>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex gap1Rem'>
            <Link to={`/claims/${row.original.claimNo}`}>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                title='View Claim'
              >
                View Claim
              </Button>
            </Link>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              title='View Report'
              onClick={() => {
                viewReport(row.original);
              }}
            >
              View Report
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              title='Reassign'
              onClick={() => {
                handleReassign(row.original);
              }}
            >
              Reassign
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Claim No',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Assigned To',
      accessorKey: 'assignedTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Started',
      accessorKey: 'dateStarted',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateStarted ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
