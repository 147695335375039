import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PaymentsApi from '../../../Api/PaymentsApi';
import { TransferPaymentRequest } from '../../../ApiTypes/TransferPaymentRequest';
import { VPaymentTab } from '../../../ApiTypes/VPaymentTab';
import { useAppSelector } from '../../../Reducers/Store';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import EditCreatePayment from '../../EditCreatePayment/EditCreatePayment';
import TransactionDocsModal from '../../TransactionDocs/TransactionDocsModal';
import TransferPaymentModal from '../../TransferPaymentModal/TransferPaymentModal';
import PaymentsTable from './PaymentsTable';
import StopPaymentModal from './StopPaymentModal';
import { UserEmailList } from '../../../ApiTypes/UserEmailList';
import UserEmailListApi from '../../../Api/UserEmailListApi';
import { StopPaymentRequest } from '../../../ApiTypes/StopPaymentRequest';
import { Button } from 'react-bootstrap';

export default function PaymentsTab({ claimType }: { claimType: number }) {
  let { claimNumber } = useParams();
  const [payments, setPayments] = useState<VPaymentTab[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<VPaymentTab | null>(
    null
  );
  const [paymentActionType, setPaymentActionType] = useState<string>('');
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [showStopPaymentModal, setShowStopPaymentModal] =
    useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showTransferPaymentModal, setShowTransferPaymentModal] =
    useState<boolean>(false);
  const [showViewDocsModal, setShowViewDocsModal] = useState<boolean>(false);
  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);

  const { claimant, claim } = useAppSelector(
    (state) => state.currentClaimReducer
  );
  const { userModel } = useAppSelector((state) => state.user);

  const getPayments = useCallback(() => {
    if (claimNumber) {
      PaymentsApi.getPaymentsByClaimNumber(claimNumber)
        .then((res) => {
          setPayments(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPayments([]);
    }
  }, [claimNumber]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const shouldTransferPayment = (payment: VPaymentTab) => {
    let now = new Date();
    let begin = new Date(now.getFullYear(), now.getMonth(), 1);
    setSelectedPayment(payment);

    if (payment.txDate && new Date(payment?.txDate) < begin) {
      setShowConfirmModal(true);
    } else {
      setShowTransferPaymentModal(true);
    }
  };

  const cancelConfirmModal = (show?: boolean) => {
    setShowConfirmModal(false);
  };

  const confirmContinueToTransfer = () => {
    setShowConfirmModal(false);
    setShowTransferPaymentModal(true);
  };

  const transferPayment = (request: TransferPaymentRequest) => {
    return PaymentsApi.transferPayment(request)
      .then((res) => {
        if (res.data.success) {
          setSelectedPayment(null);
          setShowTransferPaymentModal(false);
          getPayments();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shouldVoidPayment = (payment: VPaymentTab) => {
    setSelectedPayment(payment);
    setPaymentActionType('void');
    setShowStopPaymentModal(true);
  };

  const shouldStopPayment = (payment: VPaymentTab) => {
    setSelectedPayment(payment);
    setPaymentActionType('stop');
    setShowStopPaymentModal(true);
  };

  const stopPayment = (values: StopPaymentRequest) => {
    if (!selectedPayment) {
      return Promise.resolve();
    }
    values.adjusterId = claim?.adjuster ?? 0;
    values.claimNumber = claim?.claimNo ?? '';
    return PaymentsApi.stopPaymentRequest(values)
      .then((res) => {
        if (res.data.success) {
          setShowStopPaymentModal(false);
          // getPayments();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewDocs = (payment: VPaymentTab) => {
    setSelectedPayment(payment);
    setShowViewDocsModal(true);
  };

  const hideShowPaymentModal = (show: boolean) => {
    setShowPaymentModal(false);
    getPayments();
  };

  const testACHPayment = () => {
    return PaymentsApi.processACHPayment(289109, 3819675)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed');
      });
  };

  return (
    <div className='w-100 h-100'>
      {/* <Button type='button' onClick={testACHPayment}>
        Test ACH
      </Button> */}
      <PaymentsTable
        payments={payments}
        getPayments={getPayments}
        show={showPaymentModal}
        setShow={setShowPaymentModal}
        handleViewDocs={handleViewDocs}
        setSelectedPayment={setSelectedPayment}
        selectedPayment={selectedPayment}
        transferPayment={shouldTransferPayment}
        shouldVoidPayment={shouldVoidPayment}
        shouldStopPayment={shouldStopPayment}
      />
      {showPaymentModal && (
        <EditCreatePayment
          show={showPaymentModal}
          setShow={hideShowPaymentModal}
          selectedPayment={selectedPayment}
          getPayments={getPayments}
          claimType={claimType}
        />
      )}
      <StopPaymentModal
        show={showStopPaymentModal}
        setShow={setShowStopPaymentModal}
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        userEmailList={userEmailList}
        stopPayment={stopPayment}
        toEmailAddresses={[
          'colmstead@georgia-admin.com',
          'mhensley@georgia-admin.com',
        ]}
        paymentActionType={paymentActionType}
      ></StopPaymentModal>
      <ConfirmModal
        show={showConfirmModal}
        setShow={cancelConfirmModal}
        confirm={confirmContinueToTransfer}
        message={<Message />}
      />
      <TransferPaymentModal
        setShow={setShowTransferPaymentModal}
        show={showTransferPaymentModal}
        selectedPayment={selectedPayment}
        transferPayment={transferPayment}
        setSelectedPayment={setSelectedPayment}
      />
      <TransactionDocsModal
        show={showViewDocsModal}
        setShow={setShowViewDocsModal}
        selectedPayment={selectedPayment}
        claimantName={`${claimant?.firstName} ${claimant?.lastName}`}
      />
    </div>
  );
}

const Message = () => (
  <div>
    <p>This transaction occurred in a prior period.</p>
    <p>
      If you continue, it will result in a void on this claim and a new
      transaction in the receiving claim.
    </p>
    <p>Do you wish to continue?</p>
  </div>
);
