import React from 'react';
import styles from './index.module.css';

const redBorder = {
  borderColor: 'red',
};

type FinalRenderSelectProps = {
  input: any;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  onChange: (value: any) => void;
  meta: { touched: boolean; error: string; warning: string };
  showNullOption?: boolean;
};

export default function FieldBSRenderYesNoSelect({
  input,
  label,
  required,
  autoFocus,
  placeholder,
  name,
  disabled,
  onChange,
  showNullOption = true,
  meta: { touched, error, warning },
}: FinalRenderSelectProps) {
  return (
    <div className={`position-relative ${styles.vFieldHeight}`}>
      <label htmlFor={input.name} className='form-label fs-6  m-0'>
        {label}
      </label>
      <select
        {...input}
        id={input.name}
        disabled={disabled}
        onChange={(e) => {
          input.onChange(e);
          onChange && onChange(e.target.value);
        }}
        style={touched && error ? redBorder : {}}
        className='form-select form-select-sm'
        autoComplete='off'
      >
        <option value=''>--Select--</option>
        <option value='Y'>Yes</option>
        <option value='N'>No</option>
      </select>
      {touched &&
        ((error && (
          <span className={`${styles.fieldError} text-danger`}>{error}</span>
        )) ||
          (warning && (
            <span className={`${styles.fieldWarning} text-warning`}>
              {warning}
            </span>
          )))}
    </div>
  );
}
