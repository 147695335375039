import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import styles from './index.module.css';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import { useAppSelector } from '../../Reducers/Store';
import { EntityUser } from '../../ApiTypes/EntityUser';
import { useState } from 'react';
import { FormApi } from 'final-form';
import { NcmFormReportSetup } from '../../ApiTypes/NcmFormReportSetup';
import { addDays, format } from 'date-fns';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import {
  setShowDocView,
  setDocViewFileDownload,
} from '../../Actions/DocViewActions';
import { getDxReport } from '../DocViewModal/useDocViewModal';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';
import { FileDownload } from '../../Types/FileDownLoad';
import { equals } from '@jest/expect-utils';
import { parseDatesForServer } from '../../Utils';

export default function NcmReportSetup({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { nurseCaseManagers } = useAppSelector((state) => state.reference);
  const [ncm, setNcm] = useState<string>('');
  //   const [ncmReportSetup, setNcmReportSetup] = useState<NcmReportSetup | null>(
  //     null
  //   );
  let formInstance: FormApi<NcmFormReportSetup, Partial<NcmFormReportSetup>>;

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  //   const viewReport = (obj: NcmFormReportSetup) => {
  //     var now = new Date();
  //     var asOf = format(obj.asOfDate, 'yyyy-MM-dd');

  //     const request: DevExpressReportRequest = {
  //       ...requestDx,
  //       report: DxAvailableReports.NCMReportByManager,
  //       asOfDate: asOf,
  //       userId: obj.ncmUser,
  //     };
  //     // getDxReport(request, setFileDownload, setShowDx);
  //   };

  const onSubmit = (values: NcmFormReportSetup) => {
    //   return handleAddDocument(values);
    // return viewReport(values);
    var asOf = values.asOfDate.toString();
    // var asOf = format(values.asOfDate, 'yyyy-MM-dd');
    var reportType = 1;
    if (values.detailedSummary === 'detailed') reportType = 1;
    else if (values.detailedSummary === 'summary') reportType = 2;

    if (!values.straightToExcel) {
      //Summary Report
      const request: DevExpressReportRequest = {
        ...requestDx,
        report: DxAvailableReports.NCMReportByManager,
        asOfDate: asOf,
      };
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Ncm-Report-Setup-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title>NCM Report Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className='mb-2'>
            <h5>Nurse Case Management Billout Report</h5>
          </div>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              ncmUser: '',
              asOfDate: '',
              detailedSummary: 'detailed',
              printOneAccountPerPage: false,
              straightToExcel: false,
              //   claimNumber,
              //   docTypeId: DocumentType.PropertyPhoto,
              //   action: 'None',
              //   newName: selectedPhoto?.notes,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              form = formInstance;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.form}`}>
                    <div className={`${styles.column}`}>
                      <Field
                        name='asOfDate'
                        label='As of Date:'
                        component={FieldBSRenderDate}
                        parse={parseDatesForServer}
                      />
                      <div className='mb-3'>
                        <label htmlFor='ncm' className='form-label fs-6  m-0'>
                          Nurse Case Manager
                        </label>
                        <select
                          id='ncmUser'
                          onChange={(e) => {
                            setNcm(e.target.value);
                          }}
                          className='form-select form-select-sm'
                        >
                          <option value=''>All</option>
                          {nurseCaseManagers.map((o) => (
                            <option key={o.userId} value={o.userId}>
                              {o.userName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* <Field
                    name='ncmUser'
                    label='NCM User'
                    options={nurseCaseManagers}
                    optionMethod={(options: EntityUser[]) =>
                      options.map((o) => (
                        // <option value=''>All</option>
                        <option key={o.userId} value={o.userId}>
                          {o.userName}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  /> */}
                  <div className={`${styles.actionButtons} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Generate Report'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        // form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
