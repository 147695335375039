import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { UserInsuranceCompanyAccess } from '../../ApiTypes/UserInsuranceCompanyAccess';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';

export default function UserAccessTable({
  data,
  handleDelete,
  handleEdit,
  handleLimit,
}: {
  data: UserInsuranceCompanyAccess[];
  handleDelete: (a: UserInsuranceCompanyAccess) => void;
  handleEdit: (a: UserInsuranceCompanyAccess) => void;
  handleLimit: (a: UserInsuranceCompanyAccess) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<UserInsuranceCompanyAccess>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center gap1Rem align-items-center'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Edit'
              onClick={() => {
                handleEdit(row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              title='Limit access to employers'
              onClick={() => {
                handleLimit(row.original);
              }}
            >
              Limit to Employers
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'User Id',
      accessorKey: 'userId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'User Name',
      accessorKey: 'user.userName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Ins Company',
      accessorKey: 'insurCo.fullname',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Ins Co Id',
      accessorKey: 'insurCo.accountnumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
