import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';

export default function EdiDetailTable({
  transactions,
}: {
  transactions: EdiTransaction[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const tableData = useMemo(() => transactions, [transactions]);

  const columnData: ColumnDef<EdiTransaction>[] = [
    {
      header: 'Benefit',
      accessorKey: 'benefitTypeName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Type',
      accessorKey: 'maintTypeCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Start',
      accessorKey: 'benefitStartDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.benefitStartDate ?? ''),
    },
    {
      header: 'End',
      accessorKey: 'benefitThroughDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.benefitThroughDate ?? ''),
    },
    {
      header: 'Actual RTW',
      accessorKey: 'initialReturnToWorkDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        displayDateOnly(d.row.original.initialReturnToWorkDate ?? ''),
    },
    {
      header: 'Suspend Date',
      accessorKey: 'benefitSuspendDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.benefitSuspendDate ?? ''),
    },
    {
      header: 'Body Code',
      accessorKey: 'ppdimpairmentBodyPartCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Rating',
      accessorKey: 'permImpairmentPercentage',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Weeks',
      accessorKey: 'ppdimpairmentWeeks',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'AVG Wage',
      accessorKey: 'avgWeeklyWage',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'WCR',
      accessorKey: 'weeklyBenefitAmt',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Weeks Owed',
      accessorKey: 'weeksOwed',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Weeks Paid',
      accessorKey: 'weeksPaid',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Ind Due',
      accessorKey: 'amountOwed',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.amountOwed ?? 0),
    },
    {
      header: 'Ind Paid',
      accessorKey: 'amountPaid',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.amountPaid ?? 0),
    },
    {
      header: 'Over/Under Total',
      accessorKey: 'overUnderPayment',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) =>
        accounting.formatMoney(row.original.overUnderPayment ?? 0),
    },
  ];

  const columns = useMemo(() => columnData, []);
  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });
  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={false}
      selectableRow={true}
    />
  );
}
